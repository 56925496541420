import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, retry, catchError } from 'rxjs';
import { handleError } from '../utils/generic-utils';

export interface TizenAppVersionDetails {
  version: number;
  total_displays: number;
  release_date: string;
  control_plane_version: number;
  is_current: boolean;
  deployment_date?: string;
}

export interface TizenAppDetailsResponse {
  installer_url: string;
  versions: TizenAppVersionDetails[];
}

export interface IFailedDeployDisplayTag {
  id: string;
  name: string;
}

export interface IFailedDeployDisplay {
  name: string;
  serial: string;
  code: string;
  device_group_id: string | null
  tags: IFailedDeployDisplayTag[];
  locations: IFailedDeployDisplayTag[];
  configuration_id: string | null;
  created_on: string;
  updated_on: string;
  last_reported_on: string;
}

@Injectable({
  providedIn: 'root'
})
export class TizenAppService {
  endpoint: string = environment.performTVApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: "json"
  });
  retry = 0;

  constructor(private http: HttpClient) {}

  getTizenApps(): Observable<TizenAppDetailsResponse> {
    let api = `${this.endpoint}/v2/applications/iptv`;
    return this.http
      .get<TizenAppDetailsResponse>(api, {
        headers: this.headers,
        responseType: 'json'
      })
      .pipe(retry(this.retry), catchError(handleError));
  }

  updateTizenAppVersion(version: number): Observable<void> {
    let api = `${this.endpoint}/applications/iptv/version`;
    return this.http
      .post<void>(api, { 
        new_sssp_version: version
       }, {
        headers: this.headers,
        responseType: 'json'
      })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getAppsTizen(): Observable<any> {
    let api = `${this.endpoint}/applications/iptv`;
    return this.http
      .get(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getOutdatedDevicesByTizenVersion(version: string): Observable<IFailedDeployDisplay[]> {
    let api = `${this.endpoint}/v2/displays?sssp_app_version=${version}`;
    return this.http
      .get<IFailedDeployDisplay[]>(api, {
        headers: this.headers,
        responseType: 'json'
      })
      .pipe(retry(this.retry), catchError(handleError));
  }
}
