<mat-sidenav-container
  data-testId="side-nav-container"
  class="sidenav-container"
>
  <mat-sidenav
    data-testId="side-nav-element"
    #sidenav
    class="sidenav"
    mode="side"
    opened
    [fixedInViewport]="true"
    [fixedTopGap]="64"
    [fixedBottomGap]="93"
  >
    <!-- Sidenav upper section -->
    <div class="nav-module">
      <div class="nav-module__header">
        <svg
          style="max-width: 45px"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="54.203"
          height="30.742"
          viewBox="0 0 54.203 30.742"
        >
          <defs>
            <style>
              .cls-1,
              .cls-3 {
                fill: #fff;
              }

              .cls-1 {
                opacity: 0.92;
              }

              .cls-2 {
                clip-path: url(#clip-path);
              }
            </style>
            <clipPath id="clip-path">
              <rect
                id="Rectangle_442"
                data-name="Rectangle 442"
                class="cls-1"
                width="54.203"
                height="30.742"
                transform="translate(-0.32 -0.493)"
              />
            </clipPath>
          </defs>
          <g
            id="Mask_Group_4"
            data-name="Mask Group 4"
            class="cls-2"
            transform="translate(0.32 0.493)"
          >
            <g
              id="Group_524"
              data-name="Group 524"
              transform="translate(4.631 -0.293)"
            >
              <g
                id="Layer_2"
                data-name="Layer 2"
                transform="translate(0 4.989)"
              >
                <g id="Capa_1" data-name="Capa 1">
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    class="cls-3"
                    d="M19.764,1.46v9.3l-7.757,2.653L4.255,10.753v-8.7h0V1.454L0,0V25.355l4.249-1.46V14.869l7.757,2.655h0l7.757-2.655-.006,8.464h0V23.9l4.255,1.454V0Z"
                  />
                </g>
              </g>
              <path
                id="Exclusion_3"
                data-name="Exclusion 3"
                class="cls-3"
                d="M-37526.492,10.474h-14.145A1.57,1.57,0,0,1-37542.2,8.9V1.571A1.57,1.57,0,0,1-37540.637,0h14.145a1.571,1.571,0,0,1,1.57,1.571V8.9A1.571,1.571,0,0,1-37526.492,10.474Zm-13.672-8.161V3.492h2.707V8.913h1.18V3.492h2.7V2.314ZM-37533,2.3h0l.605,1.178,2.762,5.421,2.766-5.421.6-1.178h-1.316l-.605,1.178-1.441,2.828-1.437-2.828-.605-1.178Z"
                transform="translate(37569.219 0)"
              />
            </g>
          </g>
        </svg>
      </div>
      <div class="nav-module__sub-sections">
        <!-- Configurations -->
        <div
          id="home-nav-item"
          class="sidenav__menuItem"
          routerLinkActive="active"
        >
          <a
            class=""
            data-testId="management-button"
            routerLink="/secured/management"
          >
            <svg
              id="Layer_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34.3 24.88"
            >
              <defs>
                <style>
                  .cls-1 {
                    opacity: 0.99;
                  }

                  .cls-2b {
                    fill: #fff;
                    stroke-width: 0px;
                  }
                </style>
              </defs>
              <g id="Layer_1-2">
                <g id="Group_3733" class="cls-1">
                  <g id="Group_1255">
                    <g id="Layer_2-2">
                      <g id="Layer_1-3">
                        <path
                          id="Path_1538"
                          class="cls-2"
                          d="m27,4.92h-11.07l3.87-3.89-.61-.67-4.39,4.36L10.44.36l-.66.67,3.91,3.89H2.6c-1.29,0-2.34,1.05-2.35,2.34v15.02c0,1.3,1.05,2.35,2.35,2.35h24.4c1.29,0,2.34-1.05,2.35-2.35V7.26c0-1.29-1.05-2.34-2.35-2.35m1.41,17.36c0,.77-.63,1.4-1.4,1.41H2.6c-.77,0-1.4-.64-1.4-1.41V7.26c0-.77.63-1.39,1.4-1.4h24.43c.77,0,1.4.64,1.4,1.41l-.03,15Z"
                        />
                        <path
                          id="Path_1538_-_Outline"
                          class="cls-2"
                          d="m19.2,0l.95,1.03-3.61,3.63h10.47c1.43,0,2.59,1.16,2.6,2.6v15.01c0,1.43-1.16,2.59-2.59,2.6H2.6c-1.44,0-2.6-1.16-2.6-2.6h0V7.26C0,5.83,1.16,4.66,2.6,4.66h10.49l-3.66-3.63L10.44,0l4.36,4.36L19.2,0Zm-4.4,5.07L10.44.72l-.31.31,4.17,4.14H2.6c-1.16,0-2.09.94-2.1,2.09v15.02c0,1.16.94,2.1,2.1,2.1h24.4c1.16,0,2.1-.94,2.1-2.1V7.26c0-1.16-.94-2.1-2.1-2.1h-11.67l4.12-4.14-.27-.3-4.38,4.35Zm-12.2.53h24.43c.91,0,1.65.75,1.66,1.66l-.03,15c0,.91-.74,1.66-1.65,1.66H2.6c-.91,0-1.65-.75-1.65-1.66V7.26c0-.91.74-1.65,1.65-1.65h0Zm24.4,17.82c.64,0,1.15-.52,1.16-1.16l.03-15c0-.64-.52-1.16-1.16-1.16H2.6c-.63,0-1.15.52-1.15,1.15v15.01c0,.64.52,1.16,1.16,1.16h24.4Z"
                        />
                      </g>
                    </g>
                    <path
                      id="Path_1539"
                      class="cls-2"
                      d="m17.98,10.15v3.89l-3.24,1.11-3.24-1.11v-3.64h0v-.25l-1.78-.61v10.6l1.78-.61v-3.77l3.24,1.11h0l3.24-1.11v3.54h0v.24l1.78.61v-10.6l-1.78.61Z"
                    />
                  </g>
                  <path
                    id="Path_1541"
                    class="cls-2"
                    d="m34.3,6.55c-.1.15-.28.21-.45.15-.21-.08-.23-.27-.23-.46-.02-1.14-.41-2.24-1.11-3.13-.93-1.24-2.36-2-3.9-2.09-.13,0-.26-.02-.39-.04-.18,0-.32-.15-.32-.33,0-.02,0-.03,0-.05.01-.18.17-.31.35-.3,0,0,0,0,0,0,.42-.02.84.02,1.25.12,2.41.5,4.28,2.41,4.72,4.83,0,.09.03.18.08.26v1.02Z"
                  />
                  <path
                    id="Path_1543"
                    class="cls-2"
                    d="m28.43,2.77c1.84.11,3.31,1.58,3.43,3.42.02.12,0,.24-.04.35-.06.14-.2.22-.35.2-.15,0-.28-.12-.3-.27-.04-.19-.03-.39-.07-.58-.23-1.31-1.32-2.3-2.65-2.38h-.08c-.34-.03-.5-.16-.48-.39s.19-.35.53-.33Z"
                  />
                </g>
              </g>
            </svg>
            <span class="menu-text">Configurations</span>
          </a>
        </div>

        <!-- Content Placement -->
        <div
          *ngIf="showContentPlaylist"
          class="sidenav__menuItem"
          routerLinkActive="active"
        >
          <a
            class=""
            data-testId="content-placement-button"
            routerLink="/secured/content-playlist"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="21"
              viewBox="0 0 33 21"
            >
              <defs>
                <style>
                  .cls-1,
                  .cls-2b {
                    fill: #fff;
                  }

                  .cls-2b {
                    opacity: 0.25;
                  }
                </style>
              </defs>
              <g
                id="Group_3535"
                data-name="Group 3535"
                transform="translate(0.001 0)"
              >
                <path
                  id="Rectangle_443"
                  data-name="Rectangle 443"
                  class="cls-1"
                  d="M5,1.5A3.5,3.5,0,0,0,1.5,5V16A3.5,3.5,0,0,0,5,19.5H28A3.5,3.5,0,0,0,31.5,16V5A3.5,3.5,0,0,0,28,1.5H5M5,0H28a5,5,0,0,1,5,5V16a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z"
                  transform="translate(-0.001 0)"
                />
                <path
                  id="Rectangle_444"
                  data-name="Rectangle 444"
                  class="cls-2b"
                  d="M0,0H20a2,2,0,0,1,2,2v8a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                  transform="translate(7.999 3)"
                />
                <path
                  id="Rectangle_445"
                  data-name="Rectangle 445"
                  class="cls-1"
                  d="M2,0H4A0,0,0,0,1,4,0V10a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V2A2,2,0,0,1,2,0Z"
                  transform="translate(3 3)"
                />
                <path
                  id="Rectangle_446"
                  data-name="Rectangle 446"
                  class="cls-1"
                  d="M0,0H27a0,0,0,0,1,0,0V2a2,2,0,0,1-2,2H2A2,2,0,0,1,0,2V0A0,0,0,0,1,0,0Z"
                  transform="translate(2.999 14)"
                />
              </g>
            </svg>

            <span class="menu-text">Content Placement</span>
          </a>
        </div>

        <!-- Management section -->
        <mat-expansion-panel
          *ngIf="showManagement || showTags"
          data-testId="management-expansion-button"
          class="sidenav__expansion-panel"
          (opened)="managementPanelOpenState = true"
          (closed)="managementPanelOpenState = false"
          [expanded]="managementPanelOpenState"
          hideToggle
        >
          <mat-expansion-panel-header>
            <mat-icon class="material-symbols-outlined nav-item-icon"
              >folder_managed</mat-icon
            >
            <span class="menu-text">Management</span>
          </mat-expansion-panel-header>
          <div class="nav-module__settings-sub-sections">
            <!-- Devices -->
            <div
              *ngIf="showManagement"
              class="sidenav__menuItem"
              routerLinkActive="active"
            >
              <a
                data-testId="devices-button"
                routerLink="/secured/devices"
              >
                <mat-icon class="material-symbols-outlined nav-item-icon"
                  >tv</mat-icon
                >
                <span class="menu-text">Devices</span>
              </a>
            </div>
            <!-- Tags -->
            <div
              *ngIf="showTags"
              class="sidenav__menuItem"
              routerLinkActive="active"
              style="margin-bottom: 0px;"
            >
              <a
                data-testId="tags-button"
                routerLink="/secured/tags"
              >
                <mat-icon class="material-symbols-outlined nav-item-icon"
                  >sell</mat-icon
                >
                <span class="menu-text">Tags</span>
              </a>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Web Player -->
        <div
          id="web-player-nav-item"
          *ngIf="showWebPlayer"
          class="sidenav__menuItem"
          routerLinkActive="active"
        >
          <a
            class=""
            data-testId="web-player-button"
            routerLink="/secured/web-player"
          >
          <span class="beta-layout">
            <span class="beta-layout-row">
              <mat-icon class="material-symbols-outlined nav-item-icon"
                >smart_display</mat-icon
              >
              <span class="menu-text">Web Player</span>
            </span>
            <span class="beta-layout-row">
              <span class="beta-indicator"> Beta </span>
            </span>
          </span>
          </a>
        </div>
      </div>
    </div>

    <!-- Sidenav bottom section -->
    <div class="nav-module nav-module--settings">
      <div data-testId="settings-button" class="nav-module__settings-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30.993"
          viewBox="0 0 30 30.993"
        >
          <defs>
            <style>
              .cls-1 {
                fill: #fff;
              }
            </style>
          </defs>
          <path
            id="settings_FILL0_wght200_GRAD0_opsz48"
            class="cls-1"
            d="M143.67,246.993l-.7-4.927a11.509,11.509,0,0,1-2.086-.934,10.047,10.047,0,0,1-1.924-1.358l-4.55,2.06-2.877-5.106,4.053-3q-.086-.52-.141-1.1t-.055-1.1q0-.487.055-1.065t.141-1.2l-4.053-3.036,2.877-5.007,4.517,1.993a14.6,14.6,0,0,1,1.957-1.341,9.628,9.628,0,0,1,2.053-.907l.728-4.97h5.735l.7,4.96a14.6,14.6,0,0,1,2.058.945,10.819,10.819,0,0,1,1.853,1.313l4.682-1.993,2.844,5.007-4.185,3.083a6.426,6.426,0,0,1,.19,1.141q.038.545.038,1.041a10,10,0,0,1-.055,1q-.055.54-.174,1.2l4.152,3.026-2.878,5.106-4.616-2.093a14.9,14.9,0,0,1-1.9,1.4,7.986,7.986,0,0,1-2.008.9l-.7,4.96Zm2.768-11.391a4.087,4.087,0,1,0-2.9-1.189A3.966,3.966,0,0,0,146.439,235.6Zm0-1.325a2.781,2.781,0,1,1,1.982-4.747,2.815,2.815,0,0,1,0,3.93A2.663,2.663,0,0,1,146.439,234.278ZM146.538,231.5Zm-1.795,14.172H148.3l.636-4.788a9.856,9.856,0,0,0,2.426-.977,12.025,12.025,0,0,0,2.237-1.7l4.43,1.914,1.722-3-3.914-2.9q.172-.8.263-1.442a9.11,9.11,0,0,0,.091-1.276,9.4,9.4,0,0,0-.086-1.31,13.283,13.283,0,0,0-.268-1.343l3.98-2.97-1.722-3-4.53,1.914a9.476,9.476,0,0,0-2.139-1.724,6.379,6.379,0,0,0-2.523-.952l-.57-4.788h-3.589l-.536,4.755a8.189,8.189,0,0,0-2.535.917,10.323,10.323,0,0,0-2.227,1.758l-4.464-1.881-1.722,3,3.914,2.871a7.407,7.407,0,0,0-.3,1.326,11.205,11.205,0,0,0-.091,1.459,9.942,9.942,0,0,0,.091,1.359,11.634,11.634,0,0,0,.263,1.326l-3.881,2.9,1.722,3,4.43-1.881a9.244,9.244,0,0,0,2.187,1.7,10.455,10.455,0,0,0,2.575.977Z"
            transform="translate(-131.538 -216)"
          />
        </svg>
        <span class="menu-text">Settings</span>
      </div>
      <div class="nav-module__settings-sub-sections">
        <!-- Integrations -->
        <div
          id="integrations-nav-item"
          class="sidenav__menuItem"
          routerLinkActive="active"
        >
          <a
            class=""
            data-testId="integrations-button"
            routerLink="/secured/integrations"
          >
            <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="cls-1" d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#fff"/>
            </svg>

            <span class="menu-text">Integrations</span>
          </a>
        </div>

        <!-- Stations -->
        <div
          *ngIf="epgEnabled"
          id="home-nav-item"
          class="sidenav__menuItem"
          routerLinkActive="active"
        >
          <a
            class=""
            data-testId="channels-button"
            routerLink="/secured/stations"
          >
            <svg
              id="Layer_2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 34.3 24.88"
            >
              <defs>
                <style>
                  .cls-1 {
                    opacity: 0.99;
                  }

                  .cls-2b {
                    fill: #fff;
                    stroke-width: 0px;
                  }
                </style>
              </defs>
              <g id="Layer_1-2">
                <g id="Group_3733" class="cls-1">
                  <g id="Group_1255">
                    <g id="Layer_2-2">
                      <g id="Layer_1-3">
                        <path
                          id="Path_1538"
                          class="cls-2"
                          d="m27,4.92h-11.07l3.87-3.89-.61-.67-4.39,4.36L10.44.36l-.66.67,3.91,3.89H2.6c-1.29,0-2.34,1.05-2.35,2.34v15.02c0,1.3,1.05,2.35,2.35,2.35h24.4c1.29,0,2.34-1.05,2.35-2.35V7.26c0-1.29-1.05-2.34-2.35-2.35m1.41,17.36c0,.77-.63,1.4-1.4,1.41H2.6c-.77,0-1.4-.64-1.4-1.41V7.26c0-.77.63-1.39,1.4-1.4h24.43c.77,0,1.4.64,1.4,1.41l-.03,15Z"
                        />
                        <path
                          id="Path_1538_-_Outline"
                          class="cls-2"
                          d="m19.2,0l.95,1.03-3.61,3.63h10.47c1.43,0,2.59,1.16,2.6,2.6v15.01c0,1.43-1.16,2.59-2.59,2.6H2.6c-1.44,0-2.6-1.16-2.6-2.6h0V7.26C0,5.83,1.16,4.66,2.6,4.66h10.49l-3.66-3.63L10.44,0l4.36,4.36L19.2,0Zm-4.4,5.07L10.44.72l-.31.31,4.17,4.14H2.6c-1.16,0-2.09.94-2.1,2.09v15.02c0,1.16.94,2.1,2.1,2.1h24.4c1.16,0,2.1-.94,2.1-2.1V7.26c0-1.16-.94-2.1-2.1-2.1h-11.67l4.12-4.14-.27-.3-4.38,4.35Zm-12.2.53h24.43c.91,0,1.65.75,1.66,1.66l-.03,15c0,.91-.74,1.66-1.65,1.66H2.6c-.91,0-1.65-.75-1.65-1.66V7.26c0-.91.74-1.65,1.65-1.65h0Zm24.4,17.82c.64,0,1.15-.52,1.16-1.16l.03-15c0-.64-.52-1.16-1.16-1.16H2.6c-.63,0-1.15.52-1.15,1.15v15.01c0,.64.52,1.16,1.16,1.16h24.4Z"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="Path_1541"
                    class="cls-2"
                    d="m34.3,6.55c-.1.15-.28.21-.45.15-.21-.08-.23-.27-.23-.46-.02-1.14-.41-2.24-1.11-3.13-.93-1.24-2.36-2-3.9-2.09-.13,0-.26-.02-.39-.04-.18,0-.32-.15-.32-.33,0-.02,0-.03,0-.05.01-.18.17-.31.35-.3,0,0,0,0,0,0,.42-.02.84.02,1.25.12,2.41.5,4.28,2.41,4.72,4.83,0,.09.03.18.08.26v1.02Z"
                  />
                  <path
                    id="Path_1543"
                    class="cls-2"
                    d="m28.43,2.77c1.84.11,3.31,1.58,3.43,3.42.02.12,0,.24-.04.35-.06.14-.2.22-.35.2-.15,0-.28-.12-.3-.27-.04-.19-.03-.39-.07-.58-.23-1.31-1.32-2.3-2.65-2.38h-.08c-.34-.03-.5-.16-.48-.39s.19-.35.53-.33Z"
                  />
                </g>
              </g>
            </svg>

            <span class="menu-text">Stations</span>
          </a>
        </div>

        <!-- Tizen App -->
        <div
          data-testId="tizen-button"
          class="sidenav__menuItem"
          (click)="openDialog('tizen-app')"
        >
          <span class="nav-open-dialog">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="23.92"
              viewBox="0 0 24 27.92"
            >
              <defs>
                <style>
                  .cls-1 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <g
                id="Group_3758"
                data-name="Group 3758"
                transform="translate(-31.881 -962.844)"
              >
                <g
                  id="Group_3728"
                  data-name="Group 3728"
                  transform="translate(34.987 962.844)"
                >
                  <path
                    id="Line_283"
                    data-name="Line 283"
                    class="cls-1"
                    d="M.9,23H-.9V0H.9Z"
                    transform="translate(9.005 0)"
                  />
                  <path
                    id="Path_1774"
                    data-name="Path 1774"
                    class="cls-1"
                    d="M62.007,987.874l-9.6-8.279,1.175-1.363,8.412,7.253,8.187-7.245,1.193,1.348Z"
                    transform="translate(-52.992 -963.185)"
                  />
                </g>
                <path
                  id="Line_284"
                  data-name="Line 284"
                  class="cls-1"
                  d="M24,.9H0V-.9H24Z"
                  transform="translate(31.881 989.864)"
                />
              </g>
            </svg>

            <span class="menu-text">Tizen App</span>
          </span>
        </div>

        <!-- Users -->
        <div class="sidenav__menuItem" routerLinkActive="active">
          <a data-testId="users-button" class="" routerLink="/secured/users">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.233"
              height="27.962"
              viewBox="0 0 33.233 27.962"
            >
              <defs>
                <style>
                  .cls-1 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <path
                id="Path_21"
                data-name="Path 21"
                class="cls-1"
                d="M.483,30.339a.782.782,0,0,1-.04-.366c0-1.838.019-3.677-.007-5.515A6.317,6.317,0,0,1,1.6,20.711a11.785,11.785,0,0,1,3.379-3.189A15.163,15.163,0,0,1,7.22,16.333c.082-.036.182-.044.251-.151a7.468,7.468,0,0,1-2.616-6.176,7.263,7.263,0,0,1,2.3-5A7.547,7.547,0,0,1,17.5,4.883a7.3,7.3,0,0,1,2.447,5.647,7.475,7.475,0,0,1-2.549,5.591c.367.162.708.3,1.042.464a11.942,11.942,0,0,1,4.174,3.188,7.34,7.34,0,0,1,1.643,3.354,5.062,5.062,0,0,1,.091.923q0,2.99,0,5.98c0,.1.005.206.009.309ZM9.757,16.514c-.352.116-.642.214-.933.308A13.666,13.666,0,0,0,4.5,19.12a9.165,9.165,0,0,0-2.384,2.7,4.477,4.477,0,0,0-.651,2.171c-.006,1.673,0,3.346,0,5.019,0,.089-.029.186.045.269H23.274a.368.368,0,0,0,.046-.255q0-2.525,0-5.05a3.731,3.731,0,0,0-.119-.918,7.181,7.181,0,0,0-2.093-3.411A11.941,11.941,0,0,0,16.167,16.8c-.331-.1-.661-.2-.992-.306.009-.027.009-.042.016-.046.281-.192.567-.376.842-.576A6.382,6.382,0,0,0,18.84,9.4a6.471,6.471,0,0,0-5.9-5.46A6.244,6.244,0,0,0,7.812,5.807,6.289,6.289,0,0,0,6.055,12a6.29,6.29,0,0,0,2.707,3.861C9.068,16.072,9.382,16.269,9.757,16.514Zm19.5-6.256a7.5,7.5,0,0,0-9.694-7.519,7.522,7.522,0,0,0,.295.915l.309-.074a6.411,6.411,0,0,1,2.764-.074A6.525,6.525,0,0,1,26.573,14.3a8.392,8.392,0,0,1-1.754,1.416.953.953,0,0,0-.313.241,1.2,1.2,0,0,0,.336.125,17.4,17.4,0,0,1,2.1.73,10.982,10.982,0,0,1,4.468,3.434,5.706,5.706,0,0,1,1.252,3.429c.009,1.591,0,3.181,0,4.772a.436.436,0,0,1-.078.336h-1.17c-.413,0-.827,0-1.24,0l-1.2.007v.984h4.656a.87.87,0,0,0,.027-.315q0-2.928,0-5.856a5.126,5.126,0,0,0-.034-.649,6.837,6.837,0,0,0-1.21-3.048,11.423,11.423,0,0,0-4.13-3.571c-.5-.267-1.019-.489-1.579-.724A7.5,7.5,0,0,0,29.26,10.258Z"
                transform="translate(-0.435 -2.377)"
              />
            </svg>

            <span class="menu-text">Users</span>
          </a>
        </div>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
