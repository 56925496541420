import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../../services/user-profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /** True when the component is handling the msal repsonse */
  handleRedirect: boolean = false;
  hideLogin = true;
  // redirecting = true;

  protectLink = 'https://hub.havrion.com/login';
  contact = 'mailto:info@havrion.com';
  redirectLogging = false;

  // signUpAuthority = `https://${environment.azure_b2c.tenant}.b2clogin.com/${environment.azure_b2c.tenant}.onmicrosoft.com/${environment.azure_b2c.userFlows.signUp}`;

  /** True if the user is authenticated */
  private get isUserAuthenticated(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  constructor(
    private authService: MsalService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private userProfileService: UserProfileService,
    private msalBroadcastService:MsalBroadcastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // console.log('init', this.hideLogin, this.handleRedirect);
    
    if (this.isUserAuthenticated) {
      this.router.navigateByUrl('/secured'); // Navigate to the secured home page
    }

    this.msalBroadcastService.inProgress$.subscribe((res) => {
      // console.log('msalBroadcastService inProgress', res);

      this.handleRedirect = true;

      // console.log('this.handleRedirect', this.handleRedirect);
    });

    // Check if the user has been redirected from the secured area
    this.route.queryParams.subscribe(params => {
      const previousSessionMsg = params['session'];

      if (previousSessionMsg) {
        switch (previousSessionMsg) {
          case 'invalid':
            this.openSnackBar('There is an issue with your user information. Please contact support.', 'Ok');
            break;
          case 'expired':
            this.openSnackBar('Your session expired. Please login again.', 'Ok');
            break;
        }
        // Remove query params
        this.router.navigate(['/login'], { replaceUrl: true });
      }
    });

    // this.msalBroadcastService.inProgress$.subscribe((res) => {
    //   console.log('msalBroadcastService inProgress', res);

    //   this.handleRedirect = res == 'handleRedirect';

    //   console.log('this.handleRedirect', this.handleRedirect);
    //   if (this.handleRedirect) {
    //     this.hideLogin = true;
    //   }

    //   console.log('this.isUserAuthenticated', this.isUserAuthenticated);

    //   if (this.isUserAuthenticated) {
    //     this.getUserProfile();
    //   }
    // });

    // this.msalBroadcastService.msalSubject$.subscribe((res) => {
    //   console.log('msalBroadcastService.msalSubject', res);
    //   if (res.error?.message.includes('AADB2C90091')) {
    //     console.log('entra');
    //     this.router.navigateByUrl('/login');
    //   }

    //   if (res.eventType == 'msal:handleRedirectEnd') {
    //     this.hideLogin = true;
    //     if (this.isUserAuthenticated) {
    //       this.getUserProfile();
    //     }
    //   }
    // });

    this.authService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        // console.log('authResult login', JSON.stringify(authResult));
        if (authResult) {
          if (authResult.expiresOn) {
            let expFullDate = new Date(authResult.expiresOn);
            let now = new Date();
            if (expFullDate.getTime() <= now.getTime()) {
              this.authService.logout();
              this.openSnackBar('Your session has expired', 'Ok');
            }
          } else {
            if (
              authResult.idTokenClaims['acr'] ===
              environment.azureB2C.userFlows.passwordReset
            ) {
              setTimeout(() => {
                this.authService.logout();
              }, 5000);
              this.openSnackBar('Password had been updated', 'Ok');
              return;
            } else {
              // this.hideLogin = true;
              if (this.isUserAuthenticated) {
                this.handleRedirect = false;
                // this.getUserProfile();
                this.router.navigateByUrl('/secured');  
              }else{
                this.hideLogin = false;
              }
            }
          }
        }else{
          this.hideLogin = false;
        }
      },
      error: (error) => {
        if (error.message.includes('AADB2C90091')) {
          window.location.reload();
        }
        if (error.message.includes('AADB2C90118')) {
          let passUrl = `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com/${environment.azureB2C.userFlows.passwordReset}`;
          this.authService.loginRedirect({
            scopes: ['openid'],
            authority: passUrl,
          });
        }
      },
    });
  }

  // async getUserProfile() {
  //   await this.userProfileService.getUserProfile();
  //   this.router.navigateByUrl('/secured');
  // }

  newUser() {
    // this.authService.authority = this.signUpAuthority;
    // console.log("authservice", this.authService.authority);
    // this.authService.loginRedirect();
    this.authService.loginRedirect({
      scopes: ['openid'],
      authority: `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com/${environment.azureB2C.userFlows.signUp}`,
    });
  }

  logInPressed(product: any) {
    this.redirectLogging = true;
    if (product === 'protect') {
      this.authService.loginRedirect({
        scopes: ['openid'],
        authority: `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com/${environment.azureB2C.userFlowsProtect.signIn}`,
        redirectUri: environment.azureB2C.redirectUri.protect,
      });
    } else {
      this.authService.loginRedirect();
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }
}
