import { Component, OnInit } from '@angular/core';
import { EULAResponse } from '../../interfaces/eulaResponse';
import { EulaService } from '../../services/eula.service';

@Component({
  selector: 'app-terms-use-view',
  templateUrl: './terms-use-view.component.html',
  styleUrls: ['./terms-use-view.component.scss']
})
export class TermsUseViewComponent implements OnInit {

  constructor(private eulaService: EulaService) { }

  termsText: string = 'Loading...'

  ngOnInit(): void {
    this.eulaService.getTermsUseNoAuth().subscribe({
      next: (res: EULAResponse) => {
        this.termsText = res.terms_and_condition.replace(/&nbsp;/g, ' ');
      }
    })
  }

  return(){
    window.history.go(-1); 
    return false;
  }

}
