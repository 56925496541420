import { Component, HostListener, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { UserProfileService } from './core/services/user-profile.service';

const THUMBUP_ICON =
  `
<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px">
  <path d="M0 0h24v24H0z" fill="#ffffff"/>
  <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.` +
  `44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5` +
  `1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"/>
</svg>
`;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:keydown', ['$event'])
  onUserActivity(): void {
    // Update lastActivityTime with the current timestamp on user activity
    this.userProfileService.onUserActivity();
  }
  /** auth service and broadcast services need to be injected HERE... Otherwise other modules won't be able to use them */
  constructor(
    private userProfileService: UserProfileService, 
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      // `icon_label`,
      // `path_to_custom_icon.svg`
      'unicorn',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/icon-displays.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'thumbs-up',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/icon-displays.svg'
      )
    );
    // You can also add the SVG as a string literal here:
    // matIconRegistry.addSvgIconLiteral('thumbs-up', domSanitizer.bypassSecurityTrustHtml(THUMBUP_ICON));
  }

  ngOnInit() {
  }

  
}
