import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, map, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { handleError } from '../utils/generic-utils';
import inputProgramToOutgoing from '../../features/secured-skeleton/extended-program-guide/utils/inputProgramToOutgoing';
import toInputProgram from 'src/app/features/secured-skeleton/extended-program-guide/utils/toInputProgram';
import { IIncomingProgram } from 'src/app/features/secured-skeleton/extended-program-guide/types';

export type ChannelId = string;

export interface IChannel {
  id: ChannelId;
  /**
   * name of the channel
   */
  name: string;
  /**
   * URL to the logo that should be displayed for this channel
   */
  logo_url: string;
  /**
   * URL to the stream that should be played for this channel
   */
  stream_address: string;
}

export enum WeekDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export interface IEpisodeSportTeam {
  id: string;
  name: string;
}

export enum RecurrenceType {
  Week,
  Day,
  Month
}

export type EpisodeRecurrence =
  | IEpisodeDayRecurrence
  | IEpisodeWeekRecurrence
  | IEpisodeMonthRecurrence;

/**
 * episode occurs every $repeat days from the start date
 */
export interface IEpisodeDayRecurrence {
  /**
   * type of the recurrence
   */
  type: RecurrenceType.Day;
  /**
   * number of days to repeat from the start date
   */
  repeat: number;
}

/**
 * episode occurs every $repeat weeks from the start date, in the $week_days
 */
export interface IEpisodeWeekRecurrence {
  /**
   * type of the recurrence
   */
  type: RecurrenceType.Week;
  /**
   * number of weeks to repeat from the start date
   */
  repeat: number;
  /**
   * week days of the where we should repeat this recurrence
   */
  week_days: WeekDay[];
}

export enum MonthRecurrenceType {
  Day,
  WeekDay
}

export enum MonthWeekDayRecurrenceType {
  First,
  Second,
  Third,
  Fourth,
  Last
}

/**
 * episode occurs every $repeat months from the start date
 */
export interface IEpisodeMonthRecurrence {
  type: RecurrenceType.Month;
  /**
   * number of months to repeat from the start date
   */
  repeat: number;
  /**
   * whether it occurs every $day of the month, or every $order $week_day of the month
   */
  recurrence:
    | {
        type: MonthRecurrenceType.Day;
        day: number;
      }
    | {
        type: MonthRecurrenceType.WeekDay;
        order: MonthWeekDayRecurrenceType;
        week_day: WeekDay;
      };
}

export enum EpisodeQuality {
  Standard = 1,
  HD = 2
}

export interface IInputEpisode {
  /**
   * Optional show type
   */
  show_type: string | null;
  /**
   * episode title
   */
  title: string;
  /**
   * episode name
   */
  name: string;

  /**
   * episode name
   */
  id?: string;
  /**
   * viewer rating
   */
  viewer_rating: string;
  /**
   * episode description
   */
  description: string;
  /**
   * URL to the episode's show thumbnail
   */
  show_thumbnail_url: string | null;
  /**
   * URL to the episode's title thumbnail
   */
  title_thumbnail_url: string | null;
  /**
   * episode quality
   */
  quality: EpisodeQuality | 0;
  /**
   * start date in seconds
   */
  start_date: number;
  /**
   * episode duration in minutes. this and `start_date` should be used to determine
   * what is the `end_date` of the episode, except if `recurrence` property is set
   */
  duration: number;
  /**
   * schedule information about this episode
   */
  schedule: EpisodeSchedule;
  /**
   * Optional star rating
   */
  starRating: string | null;
  /**
   * Program options
   */
  options: IProgramOptions;
}

export interface IProgramOptions {
  /**
   * Indicates whether the show has subtitles.
   */
  hasSubtitles: boolean;
  /**
   * Indicates whether the show has captions. This field is required.
   * The default value is false (represented as 0 in the database).
   */
  hasClosedCaptions: boolean;
  /**
   * Programs's host
   */
  host: string | null;
  /**
   * Episode information
   */
  episode: IProgramEpisodeOptions | null;
  /**
   * Game information
   */
  game: {
    name: string;
    team_1: string;
    team_2: string;
    location: string;
  } | null;
}

export interface IProgramEpisodeOptions {
  /**
   * Episode number
   */
  number: number;
  /**
   * Title of the episode
   */
  title: string;
  /**
   * Season number of the episode
   */
  season: number;
  /**
   * Year
   */
  year: number;
  /**
   * Cast of the episode
   */
  cast: string;
}

export enum ProgramFlag {
  Subtitles,
  Captions
}

export enum EpisodeScheduleType {
  Definite,
  Recurring,
  Indefinite
}

/**
 * it represents that the episode is scheduled to run from the episode start time, until start time + duration
 */
export interface IDefiniteEpisodeSchedule {
  type: EpisodeScheduleType.Definite;
}

/**
 * it represents that the episode is scheduled to run from the episode start time, until forever
 */
export interface IIndefiniteEpisodeSchedule {
  type: EpisodeScheduleType.Indefinite;
}

/**
 * it represents that the episode is scheduled to run from the episode start time, until end time defined in the recurrence dialog,
 * but only on the days matched by the data available in the `recurrence` property
 */
export interface IRecurringEpisodeSchedule {
  type: EpisodeScheduleType.Recurring;
  /**
   * recurrence information about this episode
   */
  recurrence: EpisodeRecurrence;
  /**
   * end date of the recurrence in seconds. if null, it means that the recurrence is indefinite
   */
  end_date: number | null;
}

export type EpisodeSchedule =
  | IDefiniteEpisodeSchedule
  | IRecurringEpisodeSchedule
  | IIndefiniteEpisodeSchedule;

export type EpisodeId = string;

export interface IEpisode extends IInputEpisode {
  /**
   * id of the episode
   */
  id: EpisodeId;
}

export interface ICreateEpisodeResponse {
  /**
   * id of the episode
   */
  id: string;
}

export enum ExtendedProgramGuideActionType {
  SaveEpisode,
  DeleteEpisode,
  CancelChannelEpisodes
}

export interface IExtendedProgramGuideActionBase {
  /**
   * component identification who is sending the message
   */
  sender: string | null;
}

/**
 * Should be sent when the user deletes an episode from the `EpisodeDetailsDialogComponent`
 */
export interface IDeleteEpisodeAction extends IExtendedProgramGuideActionBase {
  type: ExtendedProgramGuideActionType.DeleteEpisode;
  episodeId: EpisodeId;
  channelId: ChannelId;
}

export interface IUpdateChannelAction extends IExtendedProgramGuideActionBase {
  type: ExtendedProgramGuideActionType.SaveEpisode;
  episodes: IInputEpisode[];
  channelId: ChannelId;
}

export interface ICancelChannelEpisodesEditingAction
  extends IExtendedProgramGuideActionBase {
  type: ExtendedProgramGuideActionType.CancelChannelEpisodes;
  channelId: ChannelId;
}

export type ExtendedProgramGuideAction =
  | IUpdateChannelAction
  | IDeleteEpisodeAction
  | ICancelChannelEpisodesEditingAction;

export function updateChannel(
  channelId: ChannelId,
  episodes: IInputEpisode[]
): IUpdateChannelAction {
  return {
    type: ExtendedProgramGuideActionType.SaveEpisode,
    episodes,
    sender: null,
    channelId
  };
}

export function cancelChannelEpisodesEditing(
  channelId: ChannelId
): ICancelChannelEpisodesEditingAction {
  return {
    channelId,
    sender: null,
    type: ExtendedProgramGuideActionType.CancelChannelEpisodes
  };
}

export interface IUploadedEpisodeThumbnailResponse {
  filepath: string;
}

/**
 * Temporary service for creating episodes and channels
 */
@Injectable({
  providedIn: 'root'
})
export class ExtendedProgramGuideService {
  public constructor(private readonly httpClient: HttpClient) {}
  readonly #subject = new Subject<ExtendedProgramGuideAction>();

  public listen() {
    return this.#subject.asObservable();
  }

  public broadcastAction(action: ExtendedProgramGuideAction) {
    this.#subject.next(action);
  }

  public createEpisode(
    channelId: ChannelId,
    inputProgram: IInputEpisode
  ): Observable<ICreateEpisodeResponse> {
    return this.httpClient
      .post<ICreateEpisodeResponse>(
        `${environment.performTVApi.url}/station/${channelId}/episodes`,
        inputProgramToOutgoing(inputProgram)
      )
      .pipe(retry(1), catchError(handleError));
  }

  public editEpisode(
    channelId: ChannelId,
    episodeId: EpisodeId,
    inputProgram: IInputEpisode
  ): Observable<any> {
    return this.httpClient
      .put<any>(
        `${environment.performTVApi.url}/station/${channelId}/episode/${episodeId}`,
        inputProgramToOutgoing(inputProgram)
      )
      .pipe(retry(1), catchError(handleError));
  }

  retry = 0;

  public downloadEpisodeThumbnail(episodeId: string, type: 'title_card' | 'show_card') {
    return this.httpClient.get( `${environment.performTVApi.url}/epg/episode/${episodeId}/file/download?type=${type}`, {
      responseType: 'blob'
    });
  }

  public uploadEpisodeThumbnail(payload: Object) {
    return this.httpClient.post<IUploadedEpisodeThumbnailResponse>(
      `${environment.performTVApi.url}/epg/file/upload`,
      payload, { reportProgress: true })
    .pipe(retry(this.retry), catchError(handleError));
  }

  public getEpisodes(
    channelId: ChannelId
  ): Observable<ReadonlyArray<IEpisode>> {
    return this.httpClient
      .get<IIncomingProgram[]>(
        `${environment.performTVApi.url}/station/${channelId}/episodes`
      )
      .pipe(retry(1), catchError(handleError))
      .pipe(
        map((episodes) =>
          episodes.map((ep) => ({
            ...toInputProgram(ep),
            id: ep.id
          }))
        )
      );
  }

  // TODO: changes should be Partial<IInputEpisode>
  public updateEpisode(
    channelId: ChannelId,
    id: EpisodeId,
    changes: IInputEpisode
  ) {
    return this.httpClient
      .put<IEpisode>(
        `${environment.performTVApi.url}/station/${channelId}/episodes/${id}`,
        inputProgramToOutgoing(changes)
      )
      .pipe(retry(1), catchError(handleError));
  }

  public deleteEpisode(channelId: ChannelId, id: EpisodeId) {
    return this.httpClient
      .delete<void>(
        `${environment.performTVApi.url}/station/${channelId}/episode/${id}`
      )
      .pipe(retry(1), catchError(handleError));
  }

  public clearChannelEpisodes(channelId: ChannelId) {
    return this.httpClient
      .delete<void>(
        `${environment.performTVApi.url}/epg/channels/${channelId}/episodes`
      )
      .pipe(retry(1), catchError(handleError));
  }
}
