<div *ngIf="isLoadingUserProfile else readyBody">
    <loading-screen [fontSize]="18"></loading-screen>
</div>
<ng-template #readyBody>
    <div class="main-container">
        <app-header></app-header>
        <app-sidenav></app-sidenav>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer></app-footer>
</ng-template>

