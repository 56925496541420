import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ITizenAppsDialogNavigationHeaderButton {
  id: string;
  title: string;
  /**
   * Material icon name (i.e. arrow_back, close, etc.)
   */
  icon: string;
  /**
   * Icon position
   */
  position?: 'right';
}

@Component({
  selector: 'app-tizen-apps-dialog-navigation-header',
  templateUrl: './tizen-apps-dialog-navigation-header.component.html',
  styleUrls: ['./tizen-apps-dialog-navigation-header.component.scss']
})
export class TizenAppsDialogNavigationHeaderComponent implements OnInit {
  @Input() title: string = 'Tizen SSSP App'
  @Input() buttons: ITizenAppsDialogNavigationHeaderButton[] = []
  /**
   * Buttons that will be visible on the right side of the header
   */
  @Input() rightButtons: ITizenAppsDialogNavigationHeaderButton[] = []
  /**
   * Will be called with the button id that was clicked
   */
  @Output() buttonClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
}
