import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService, AvailableFeature } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class DevicesGuard implements CanActivate {

  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userProfileService.userProfile) {
      return this.userProfileService.getUserProfile().then(() => {
        return this.checkDeviceManagementFeature();
      });
    } else {
      return this.checkDeviceManagementFeature();
    }
  }

  private checkDeviceManagementFeature(): boolean {
    if (this.userProfileService.isFeatureEnabled(AvailableFeature.DeviceManagement)) {
      return true;
    }

    this.router.navigate(['/404']);
    return false;
  }
}
