<div class="tizen-apps-dialog w-100 h-100">
  <div class="tizen-apps-dialog__header">
    <div class="tizen-apps-dialog__header-image">
      <img src="../../../../../assets/images/Havrion-Small-Icon.svg" />
    </div>
    <div class="tizen-apps-dialog__header-title">
      HAVRION IPTV APP
    </div>
  </div>
  <app-tizen-apps-dialog-navigation-header
    [buttons]="buttons"
    [rightButtons]="rightButtons"
    *ngIf="currentDialogContent !== null"
    [title]="currentDialogContent.title"
    (buttonClick)="onNavigationHeaderButtonClick($event)"
  ></app-tizen-apps-dialog-navigation-header>

  <div class="tizen-apps-dialog__contents">
    <ng-container *ngIf="currentDialogContent !== null; else table">
      <ng-container *ngIf="currentDialogContent.type === TizenAppsDialogContentType.ReleaseNotes">
        <app-tizen-app-release-notes
          [version]="currentDialogContent.element"
        ></app-tizen-app-release-notes>
      </ng-container>
      <!-- Inside the template there is already an ngIf that only renders the confirmation in case it is in the correct state -->
      <ng-container *ngTemplateOutlet="controlPlaneConfirmation"></ng-container>
      <ng-container *ngTemplateOutlet="unaffectedDisplays"></ng-container>
    </ng-container>
    <ng-template #table>
      <app-tizen-apps-dialog-table
        #tizenAppsDialogTable
        [tizenAppDetails]="tizenAppDetails"
        [deployingMode]="deployingMode"
        (selectedAppVersionChange)="onVersionSelect($event)"
        (onRowButtonClicked)="onRowButtonClicked($event)"
        (viewOutdatedDisplaysInfo)="onViewOutdatedDisplaysInfo($event)"
      ></app-tizen-apps-dialog-table>
    </ng-template>
  </div>
</div>

<ng-template #controlPlaneConfirmation>
  <div
    *ngIf="currentDialogContent !== null && currentDialogContent.type === TizenAppsDialogContentType.UpgradeControlPlane"
    class="p-3"
  >
    <div class="tizen-apps-dialog__contents-title">
      Upgrade Control Plane
    </div>
    <div class="tizen-apps-dialog__contents__upgrade-version-information">
      <div>
        v{{ currentDialogContent.currentVersion.control_plane_version }}
      </div>
      <img src="../../../../../assets/images/icon-arrow-right.svg" />
      <div>
        v{{ currentDialogContent.targetVersion.control_plane_version }}
      </div>
    </div>
    <div class="tizen-apps-dialog__contents__header">
      Release Notes
    </div>
    <div class="tizen-apps-dialog__contents__release-notes">
      <!-- ToDo: Add Release Notes logic -->
    </div>
  </div>
</ng-template>

<ng-template #unaffectedDisplays>
  <div
    *ngIf="currentDialogContent !== null && currentDialogContent.type === TizenAppsDialogContentType.UnaffectedDisplays"
  >
  <app-unaffected-displays-table [displays]="currentDialogContent.displays || []" [loading]="loading"></app-unaffected-displays-table>
  </div>
</ng-template>
