import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './core/components/login/login.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { PrivacyPolicyViewComponent } from './core/eula/privacy-policy-view/privacy-policy-view.component';
import { TermsUseViewComponent } from './core/eula/terms-use-view/terms-use-view.component';
import { TermsGuard } from './core/guards/terms.guard';
import { EpgGuard } from './core/guards/channels.guard';
import { LoggedSkeletonComponent } from './shared/secured-shared/logged-skeleton/logged-skeleton.component';
import { PrototypesGuard } from './core/guards/prototypes.guard';
import { DevicesGuard } from './core/guards/devices.guard';
import { TagsGuard } from './core/guards/tags.guard';
import { WebPlayerGuard } from './core/guards/web-player.guard';

const routes: Routes = [
  {
    path: 'secured',
    component: LoggedSkeletonComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'management'
      },
      {
        path: 'management',

        canActivate: [MsalGuard],

        loadChildren: () =>
          import(
            './features/secured-skeleton/management/management.module'
          ).then((m) => m.ManagementModule)
      },
      {
        path: 'users',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./features/secured-skeleton/users/users.module').then(
            (m) => m.UsersModule
          )
      },
      {
        path: 'integrations',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import('./features/secured-skeleton/integrations/integrations.module').then(
            (m) => m.IntegrationsModule
          )
      },
      {
        path: 'content-playlist',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import(
            './features/secured-skeleton/content-playlist/content-playlist.module'
          ).then((m) => m.ContentPlaylistModule)
      },
      {
        path: 'extended-program-guide',
        canActivate: [MsalGuard],
        loadChildren: () =>
          import(
            './features/secured-skeleton/extended-program-guide/extended-program-guide.module'
          ).then((m) => m.ExtendedProgramGuideModule)
      },
      {
        path: 'web-player',
        canActivate: [MsalGuard, WebPlayerGuard],
        loadChildren: () =>
          import(
            './features/secured-skeleton/web-player/web-player.module'
          ).then((m) => m.WebPlayerModule)
      },
      {
        path: 'stations',
        canActivate: [MsalGuard, EpgGuard],
        loadChildren: () =>
          import('./features/secured-skeleton/channels/channels.module').then(
            (m) => m.ChannelsModule
          )
      },
      {
        path: 'devices',
        canActivate: [MsalGuard, DevicesGuard],
        loadChildren: () =>
          import('./features/secured-skeleton/devices/devices.module').then(
            (m) => m.DevicesModule
          )
      },
      {
        path: 'tags',
        canActivate: [MsalGuard, TagsGuard],
        loadChildren: () =>
          import(
            './features/secured-skeleton/tag-management/tag-management.module'
          ).then((m) => m.TagManagementModule)
      },
      { path: '**', redirectTo: '404' }
    ]
  },
  {
    path: 'activate',
    redirectTo: 'secured/devices/activate'
  },
  {
    path: 'eula',
    children: [
      {
        path: '',
        canActivate: [TermsGuard],
        loadChildren: () =>
          import('./core/eula/eula.module').then((m) => m.EulaModule)
      },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: 'terms-of-use', component: TermsUseViewComponent },
  { path: 'privacy-policy', component: PrivacyPolicyViewComponent },
  {
    path: 'prototypes',
    canActivate: [PrototypesGuard],
    loadChildren: () =>
      import('./features/prototypes/prototypes.module').then(
        (m) => m.PrototypesModule
      )
  },
  // { path: '/secured/content-playlist', component: ContentPlaylistComponent },
  // { path: '/secured/users', component: UsersComponent },
  // { path: '/secured/management', component: ManagementComponent },
  { path: 'login', component: LoginComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
