import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject, retry, catchError, from, concatMap } from 'rxjs';
import { SubscriptionModel } from '../interfaces/subscription.interface';
import { handleError } from '../utils/generic-utils';

export interface IConfiguration {
  created_on: string;
  updated_on: string;
  id: string;
  name: string;
  total_displays: number;
  is_active: boolean;
  created_by: string;
  updated_by: string;
}

export interface ICreateConfigurationResponse {
  id: string;
}

export interface IPostImageResponse {
  fileUrl: string;
}

export interface IChannelResponse {
  id: string;
  logo_id: string;
  name: string;
  number: number;
  stream_source: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {
  endpoint: string = environment.performTVApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'json'
  });
  retry = 0;

  private subjectConfigurationSubs = new Subject<any>();

  constructor(private http: HttpClient) {}

  sendConfigurationSubs(data: SubscriptionModel) {
    this.subjectConfigurationSubs.next(data);
  }

  getConfigurationSubs() {
    return this.subjectConfigurationSubs.asObservable();
  }

  getImage(path: string): Observable<Blob> {
    const api = `${this.endpoint}/image/download?fileUrl=${path}`;
    return this.http
      .get(api, { responseType: 'blob' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  postImage(payload: FormData): Observable<IPostImageResponse> {
    const api = `${this.endpoint}/images/upload`;
    return this.http
      .post<IPostImageResponse>(api, payload)
      .pipe(retry(this.retry), catchError(handleError));
  }

  deleteConfig(configId: string): Observable<void> {
    const api = `${this.endpoint}/configuration/${configId}`;
    return this.http
      .delete<void>(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  putConfig(configId: string, payload: object): Observable<void> {
    const api = `${this.endpoint}/configuration/${configId}`;
    // return of({ success: true });
    return this.http
      .put<void>(api, payload, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  postConfigChannels(configId: string, payload: object): Observable<void> {
    const api = `${this.endpoint}/configuration/${configId}/channels`;
    // return of({ success: true });
    return this.http
      .post<void>(api, payload, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  putConfigRemoteCont(configId: string, payload: object): Observable<any> {
    const api = `${this.endpoint}/configuration/${configId}/remote-controls`;
    // return of({ success: true });
    return this.http
      .post(api, payload, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  putConfigDispGroups(
    configId: string,
    groupId: string,
    payload: object
  ): Observable<void> {
    const api = `${this.endpoint}/configuration/${configId}/displays-group/${groupId}/assign`;
    // return of({ success: true });
    return this.http
      .post<void>(api, payload, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  postConfig(payload: object): Observable<ICreateConfigurationResponse> {
    const api = `${this.endpoint}/configurations`;
    return this.http
      .post<ICreateConfigurationResponse>(api, payload, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getConfigList(): Observable<IConfiguration[]> {
    const api = `${this.endpoint}/configurations`;
    // return of([])
    return this.http
      .get<IConfiguration[]>(api, {
        headers: this.headers,
        responseType: 'json'
      })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getChannelLineUp(configId: string): Observable<IChannelResponse[]> {
    const api = `${this.endpoint}/configuration/${configId}/channels`;
    return this.http
      .get<IChannelResponse[]>(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getRemoteControl(configId: string): Observable<any> {
    const api = `${this.endpoint}/configuration/${configId}/remote-controls`;
    return this.http
      .get(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getDisplays(configId: string): Observable<any> {
    const api = `${this.endpoint}/configuration/${configId}/displays`;
    return this.http
      .get(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  getConfiguration(configId: string): Observable<any> {
    const api = `${this.endpoint}/configuration/${configId}`;
    return this.http
      .get(api, { headers: this.headers, responseType: 'json' })
      .pipe(retry(this.retry), catchError(handleError));
  }

  assignDisplaysToConfig(configId: string, deviceId: string) {
    return this.http.put(
      `${environment.performTVApi.url}/display/${deviceId}`,
      {
        configuration_id: configId
      }
    ).pipe(retry(this.retry), catchError(handleError));
  }

  assignDisplaysToConfigBulk(configId: string, deviceIds: string[]) {
    return from(deviceIds).pipe(
      concatMap((deviceId) =>
        this.assignDisplaysToConfig(configId, deviceId).pipe(retry(this.retry))
      )
    );
  }

  removeDisplaysFromConfig(deviceIds: string[]) {
    return from(deviceIds).pipe(
      concatMap((deviceId) =>
        this.http.put(`${environment.performTVApi.url}/display/${deviceId}`, {
          configuration_id: null
        }).pipe(retry(this.retry), catchError(handleError))
      )
    );
  }
}
