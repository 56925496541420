<div class="quick-confirmation-dialog">
  <div
    class="quick-confirmation-dialog__header"
    data-testId="quick-confirmation-dialog-title"
  >
    {{data.title}}
  </div>
  <div
    data-testId="quick-confirmation-dialog-body"
    class="quick-confirmation-dialog__body"
  >
    {{data.message}}
  </div>
  <div class="quick-confirmation-dialog__footer">
    <div class="quick-confirmation-dialog__actions">
      <button
        data-testId="quick-confirmation-dialog-cancel-button"
        (click)="close(false)"
        class="quick-confirmation-dialog__actions-button transparent"
      >
        Cancel
      </button>
      <button
        data-testId="quick-confirmation-dialog-action-button"
        (click)="close(true)"
        class="quick-confirmation-dialog__actions-button"
      >
        {{data.actionButtonTitle}}
      </button>
    </div>
  </div>
</div>
