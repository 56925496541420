import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpisodesCalendarViewComponent } from './episodes-calendar-view/episodes-calendar-view.component';
import { ExtendedProgramGuideService } from '../../../core/services/extended-program-guide.service';
import { SecuredMaterialModule } from 'src/app/shared/secured-material/secured-material.module';
import { SecuredSharedModule } from 'src/app/shared/secured-shared/secured-shared.module';
import { EpisodeDetailsDialogComponent } from './episode-details-dialog/episode-details-dialog.component';
import { EpisodeRecurrenceOptionsComponent } from './episode-recurrence-options/episode-recurrence-options.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { EpisodeThumbnailInputComponent } from './episode-thumbnail-input/episode-thumbnail-input.component';
import { ScheduleProgramComponent } from './schedule/schedule-program/schedule-program.component';
import { EpisodeGeneralTabComponent } from './episode-details-dialog/episode-general-tab/episode-general-tab.component';
import { BrowserModule } from '@angular/platform-browser';
import { EpisodeTimeTabComponent } from './episode-details-dialog/episode-time-tab/episode-time-tab.component';

@NgModule({
  providers: [ExtendedProgramGuideService],
  imports: [
    BrowserModule,
    CommonModule,
    SecuredSharedModule,
    SecuredMaterialModule
  ],
  declarations: [
    EpisodesCalendarViewComponent,
    EpisodeDetailsDialogComponent,
    EpisodeRecurrenceOptionsComponent,
    DeleteConfirmationDialogComponent,
    EpisodeThumbnailInputComponent,
    EpisodeGeneralTabComponent,
    EpisodeTimeTabComponent,
    ScheduleProgramComponent
  ]
})
export class ExtendedProgramGuideModule {}
