import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IFailedDeployDisplay,
  TizenAppDetailsResponse,
  TizenAppService,
  TizenAppVersionDetails
} from 'src/app/core/services/tizenApp.service';
import { DialogService, IDialog, IDialogOptions } from 'src/app/shared/secured-shared/dialog/dialog.service';
import { delay, finalize, of } from 'rxjs';
import { ITizenAppsDialogNavigationHeaderButton } from '../tizen-apps-dialog-navigation-header/tizen-apps-dialog-navigation-header.component';
import { QuickConfirmationDialogComponent } from '../quick-confirmation-dialog/quick-confirmation-dialog.component';
import { ITizenAppsDialogTableButtonClickEvent, TizenAppsDialogTableButtonType, TizenAppsDialogTableComponent } from '../tizen-apps-dialog-table/tizen-apps-dialog-table.component';
import TizenAppsVersionUtil from '../version';
import { DateTime } from 'luxon';

export enum TizenAppsDialogContentType {
  UpgradeControlPlane,
  UnaffectedDisplays,
  ReleaseNotes
}

/**
 * Upgrade control plane confirmation section
 */
export interface ITizenAppsDialogContentUpgradeControlPlane {
  type: TizenAppsDialogContentType.UpgradeControlPlane;
  title: string;
  content: string;
  currentVersion: TizenAppVersionDetails;
  /**
   * Version we're trying to upgrade to
   */
  targetVersion: TizenAppVersionDetails;
}

/**
 * Release notes section
 */
export interface ITizenAppsDialogContentReleaseNotes {
  type: TizenAppsDialogContentType.ReleaseNotes;
  title: string;
  element: TizenAppVersionDetails;
}

export interface ITizenAppsDialogContentUnaffectedDisplays {
  type: TizenAppsDialogContentType.UnaffectedDisplays;
  title: string;
  displays: IFailedDeployDisplay[];
}

export type TizenAppsDialogContent = (
  | ITizenAppsDialogContentUpgradeControlPlane
  | ITizenAppsDialogContentReleaseNotes
  | ITizenAppsDialogContentUnaffectedDisplays
);

@Component({
  selector: 'app-tizen-apps-dialog',
  templateUrl: './tizen-apps-dialog.component.html',
  styleUrls: ['./tizen-apps-dialog.component.scss']
})
export class TizenAppsDialogComponent implements OnInit, IDialog, OnDestroy {
  @ViewChild('tizenAppsDialogTable', { static: false }) tizenAppsDialogTable: TizenAppsDialogTableComponent;

  buttons: ITizenAppsDialogNavigationHeaderButton[] = [
    {
      id: 'back',
      title: 'Back',
      icon: 'arrow_back'
    }
  ]
  rightButtons: ITizenAppsDialogNavigationHeaderButton[] = []

  /**
   * `DialogService` related methods
   */
  public data: {};
  public dialogOptions: IDialogOptions;
  public close: (response: unknown) => void;

  selectedVersion: TizenAppVersionDetails | null = null
  currentDialogContent: TizenAppsDialogContent | null = null
  TizenAppsDialogContentType = TizenAppsDialogContentType;

  deploymentTimeInSeconds = 30;
  refreshInterval = 2000;
  deployingMode = false;

  expectedTotalDisplays: number = 0;

  public onCancel(): void {
    this.close(null);
  }

  public onDelete(): void {
  }

  public onConfirm(): void {
    if(this.currentDialogContent !== null) {
      switch(this.currentDialogContent.type) {
        case TizenAppsDialogContentType.ReleaseNotes:
        case TizenAppsDialogContentType.UnaffectedDisplays:
          return;
        case TizenAppsDialogContentType.UpgradeControlPlane:
          this.onConfirmControlPlaneUpgrade();
          break;
      }
      this.currentDialogContent = null;
      return;
    }

    if (this.deployingMode) {
      this.openSnackBar('Please wait for the current deploy to finish', 'Ok');
      return;
    }

    const selectedVersion = this.selectedVersion;
    const currentVersion = this.currentVersion;
    
    if(!selectedVersion) {
      console.error('No version selected');
      return;
    }
    if(selectedVersion.is_current) {
      console.error('Selected version is already current');
      return;
    }
    if(!currentVersion) {
      console.error('No current version found');
      return;
    }
    
    /**
     * Version with a lower control plane is not installable
     */
    if(selectedVersion.control_plane_version < currentVersion.control_plane_version) {
      this.openSnackBar(
        'This version has a lower control plane version. ' +
        'Control plane downgrading is not possible.',
        'Ok'
      );
      return;
    }

    const isUpgrading = selectedVersion.version > currentVersion.version;
    const isDowngrading = selectedVersion.version < currentVersion.version;
    const isUpgradingControlPlane = selectedVersion.control_plane_version > currentVersion.control_plane_version;
    
    this.dialogService.openDialog({
      content: QuickConfirmationDialogComponent,
      title: '',
      data: {
        title: `${isUpgrading ? 'Upgrading' : 'Downgrading'} to v${selectedVersion.version}`,
        message: (
          `You're about to ${isUpgrading ? 'upgrade' : 'downgrade'} from ` +
          `v${this.currentVersion.version} to v${selectedVersion.version}. ` +
          'Please make sure all devices have connection.\n\n\nAre you sure you want to proceed?'
        ),
        actionButtonTitle: isUpgrading ? 'Upgrade' : 'Downgrade',
      },
      unframed: true
    }, 
    undefined, 
    undefined, 
    {
      panelClass: 'quick-confirmation-dialog__panel',
    })
    .afterClosed()
    .subscribe(value => {
      if(!value) {
        return;
      }
      if(isUpgradingControlPlane) {
        // ToDo: Implement control plane upgrade logic
      } else if(isUpgrading || isDowngrading) {
        this.triggerVersionUpdate();
      }
    });
  }

  public upgradeControlPlane(currentVersion: TizenAppVersionDetails, targetVersion: TizenAppVersionDetails) {
    if(this.currentDialogContent !== null) {
      return;
    }
    this.dialogOptions.saveButton.label = 'UPDATE AND PROCEED';
    this.currentDialogContent = {
      type: TizenAppsDialogContentType.UpgradeControlPlane,
      title: 'Upgrade Control Plane',
      content: 'Control plane release notes.',
      currentVersion,
      targetVersion
    };
  }

  /**
   * Component starts here
   */
  public constructor(
    private readonly dialogService: DialogService,
    private tizenAppService: TizenAppService,
    private _snackBar: MatSnackBar
  ) { }

  public loading = false;

  public currentVersion: TizenAppVersionDetails | null = null;
  public readonly TizenAppsDialogTableButtonType = TizenAppsDialogTableButtonType;

  public tizenAppDetails: TizenAppDetailsResponse = {
    installer_url: '',
    versions: []
  };

  public ngOnInit(): void {
    // Set the dialog options
    this.dialogOptions.saveButton.show = true;
    this.dialogOptions.saveButton.label = 'UPGRADE';
    this.dialogOptions.saveButton.disable = false;
    this.dialogOptions.cancelButton.show = true;
    this.dialogOptions.cancelButton.label = 'CLOSE';
    this.dialogOptions.deleteButton.show = false;

    // Get the Tizen app details
    this.getTizenAppDetails();

    // Make sure no version is not selected when the dialog is opened
    this.onVersionSelect(null);

    // Read local storage for the last expectedTotalDisplays
    const expectedTotalDisplays = localStorage.getItem('expectedTotalDisplays');
    if(expectedTotalDisplays) {
      this.expectedTotalDisplays = parseInt(expectedTotalDisplays);
    }
  }

  public getTizenAppDetails(): void {
    if (!this.deployingMode) {
      this.dialogOptions.loading = true;
    }

    this.tizenAppService.getTizenApps()
      .pipe(finalize(() => {
        this.dialogOptions.loading = false;
      }))
      .subscribe({ 
        next: (res: TizenAppDetailsResponse) => {
          // Order versions by version number (first the newest version)
          res.versions.sort((a, b) => b.version - a.version);
          this.tizenAppDetails = res;

          // Get the current version
          const currentVersion = this.tizenAppDetails.versions.find(app => app.is_current) ?? null;
          this.currentVersion = currentVersion;
          
          // Check if there are total displays current version is equal or greater than the previous version
          // If so, the deployment is finished
          if(currentVersion.total_displays >= this.expectedTotalDisplays) {
            this.deployingMode = false;
            return;
          }

          // Check last deployment date
          const deploymentDate = DateTime.fromISO(currentVersion.deployment_date);
          const now = DateTime.now();
            const diff = now.diff(deploymentDate, 'seconds').seconds;

          if(diff < this.deploymentTimeInSeconds) {
            this.deployingMode = true;

            // if the deployment is still in progress, refresh the data in 10 seconds
            setTimeout(() => {
              this.getTizenAppDetails();
            }, this.refreshInterval);
          } else {
            this.deployingMode = false;
          }
        },
        error: (error) => {
          this.openSnackBar(error.message, 'Ok');
        },
      });
  }

  public triggerVersionUpdate() {
    this.dialogOptions.loading = true;

    // Save the expected total displays
    const expectedTotalDisplays = this.currentVersion.total_displays + this.selectedVersion.total_displays;
    this.expectedTotalDisplays = expectedTotalDisplays;
    localStorage.setItem('expectedTotalDisplays', expectedTotalDisplays.toString());
    
    this.tizenAppService.updateTizenAppVersion(
      this.selectedVersion.version
    ).subscribe({
      next: value => {
        // clear selected version
        this.tizenAppsDialogTable.selectedAppVersion = null;
        this.onVersionSelect(null);

        this.getTizenAppDetails();
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    });
  }

  public ngOnDestroy(): void {}

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  public onVersionSelect(version: number | null): void {
    /**
     * If we are unselecting a version, make sure we disable the current button
     */
    if(version === null) {
      this.selectedVersion = null;
      this.dialogOptions.saveButton.disable = true;
      this.dialogOptions.saveButton.label = 'UPGRADE';
      return;
    }

    const targetVersion = this.tizenAppDetails.versions.find(app => app.version === version);

    if(!targetVersion) {
      console.error('No target version found');
      this.selectedVersion = null;
      return;
    }

    if(!this.currentVersion) {
      console.error('No current version found');
      return;
    }

    this.selectedVersion = targetVersion;
    this.dialogOptions.saveButton.disable = false;

    // Check if the selected version is newer than the current version
    if(TizenAppsVersionUtil.greaterThan(targetVersion.version, this.currentVersion.version)) {
      this.dialogOptions.saveButton.label = 'UPGRADE';
    } else {
      this.dialogOptions.saveButton.label = 'DOWNGRADE';
    }
  }

  private onConfirmControlPlaneUpgrade() {
    /** ToDo: Implement ControlPlaneUpgrade logic */
  }

  onRowButtonClicked(event: ITizenAppsDialogTableButtonClickEvent) {
    switch(event.button) {
      case TizenAppsDialogTableButtonType.ReleaseNotes:
        this.currentDialogContent = {
          type: TizenAppsDialogContentType.ReleaseNotes,
          title: `Release notes for v${event.element.version}`,
          element: event.element
        };
        this.dialogOptions.saveButton.show = false;
        break;
    }
  }

  downloadFailedDeployDisplays() {
    if(this.currentDialogContent.type !== TizenAppsDialogContentType.UnaffectedDisplays) {
      return;
    }
    const {displays} = this.currentDialogContent;
    const contents = new Array<string>();
    const columnNames = [
      'Display Name',
      'Serial',
      'MAC address',
      'Last Reported On',
      'Managed By'
    ];
    contents.push(`${columnNames.map(value => `"${value}"`).join(',')}\n`);
    for (const display of displays) {
      contents.push(
        [
          display.name,
          display.serial,
          display.code,
          display.last_reported_on,
          display.device_group_id ? 'MagicInfo' : 'Perform'
        ]
          .map((value) =>
            typeof value === 'string' && value.trim().length > 0
              ? `"${value}"`
              : `"N/A"`
          )
          .join(',')
      );
      contents.push('\n');
    }

    const blob = new Blob(contents, { type: 'text/csv' });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `displays-v${this.currentVersion.version}.csv`;
    a.click();

    /**
     * Revoke the URL in 1 second to give time for the user to start downloading the file.
     * This cannot be added to the `SubscriptionManager` of this component. Otherwise, if we close
     * the dialog, the URL will never be revoked, resulting in a memory leak.
     */
    of(url).pipe(delay(1000)).subscribe({
      complete: () => {
        URL.revokeObjectURL(url);
      }
    });
  }

  onNavigationHeaderButtonClick(buttonId: string) {
    if(this.currentDialogContent === null) {
      return;
    }
    switch(buttonId) {
      case 'download-failed-deploy-display-csv':
        this.downloadFailedDeployDisplays();
        break;
      case 'back':
        this.resetNavigationState();
        break;
    }
  }

  resetNavigationState() {
    this.currentDialogContent = null;
    this.onVersionSelect(null);
    this.dialogOptions.saveButton.show = true;
  }

  outdatedDevices: IFailedDeployDisplay[] = [];

  onViewOutdatedDisplaysInfo(version: string) {
    this.loading = true;
    this.dialogOptions.loading = true;

    this.tizenAppService.getOutdatedDevicesByTizenVersion(version)
    .pipe(finalize(() => {
      this.loading = false;
      this.dialogOptions.loading = false;
    }))
    .subscribe({
      next: (res) => {
        this.outdatedDevices = res;

        this.rightButtons = [
          {
            id: 'download-failed-deploy-display-csv',
            title: 'Download CSV ',
            icon: 'download_flled',
            position: 'right'
          }
        ];

        this.currentDialogContent = {
          type: TizenAppsDialogContentType.UnaffectedDisplays,
          title: `Tizen SSSP App v${version} Displays`,
          displays: res
        };

        this.dialogOptions.saveButton.show = false;
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    });
  }
}
