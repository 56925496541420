import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecuredMaterialModule } from 'src/app/shared/secured-material/secured-material.module';
import { SecuredSharedModule } from 'src/app/shared/secured-shared/secured-shared.module';

import { SspAppComponent } from './ssp-app.component';

@NgModule({
  declarations: [SspAppComponent],
  imports: [CommonModule, SecuredSharedModule, SecuredMaterialModule]
})
export class SspAppModule {}
