import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

export class SubscriptionManager {
  private readonly subscriptions: Subscription[] = [];

  add(subscription: Subscription) {
    /**
     * Reject closed subscriptions
     */
    if(subscription.closed) {
      return;
    }
    /**
     * In case the subscription list already contains the subscription, do not add it again
     */
    if(this.subscriptions.includes(subscription)) {
      return;
    }
    /**
     * In case the subscription is closed outside of the manager, remove it from the list
     */
    subscription.add(() => {
      const index = this.subscriptions.indexOf(subscription);
      if(index >= 0) {
        this.subscriptions.splice(index, 1);
      }
    });
    this.subscriptions.push(subscription);
  }

  unsubscribe() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions.splice(0, this.subscriptions.length);
  }
}

@Injectable({
  providedIn: 'root',
})
export class SubscriptionManagerService {
  readonly #managers = new Set<SubscriptionManager>();
  public constructor() {}
  public create() {
    const manager = new SubscriptionManager();
    this.#managers.add(manager);
    return manager;
  }
}
