import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlined-icon',
  templateUrl: './outlined-icon.component.html',
  styleUrls: ['./outlined-icon.component.scss']
})
export class OutlinedIconComponent implements OnInit {
  /**
   * Refer to: https://v14.material.angular.io/components/icon/api#MatIconDefaultOptions
   */
  @Input() public fontSet: 'material-symbols-outlined' | '' = '';
  @Input() public icon: string = 'info_outline';
  @Input() public color: string | null = null;

  public constructor() {}

  public ngOnInit(): void {}
}
