<div class="hh-footer">
  <!-- <div class="hh-footer__logo">
    <img
      src="../../../../assets/images/Havrion-Logo-grey.png"
      alt="Havrion logo"
    />
  </div> -->
  
  <div class="hh-footer__links">
    2900 Monarch Lakes Blvd, Miramar, Florida 33027 &nbsp;&nbsp;|&nbsp;&nbsp;
    <a href="tel:+1-844-811-2486">+1 844.811.2486</a> &nbsp;&nbsp;|&nbsp;&nbsp;
    <a [href]="contact">Contact</a>
  </div>
  <div class="hh-footer__bottom">
    Copyright ©2022-2024 Havrion LLC All rights reserved &nbsp;&nbsp;|
    &nbsp;&nbsp;<a href="/privacy-policy">Privacy Policy</a>
    &nbsp;&nbsp;|&nbsp;&nbsp;
    <a href="/terms-of-use">Terms of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;
    <a [href]="protectLink" target="_blank">Havrion Protect</a>
  </div>
</div>
