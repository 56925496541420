/**
 * If a string is received, parse it as an integer. If it's already a number, does nothing.
 * For all cases, if it's not a valid integer, is not finite, or is NaN, return null.
 * @param n number or string to parse
 * @returns a valid finite integer that is not NaN, or null otherwise
 */
export default function parseInteger(n: string | number): number | null {
  if (typeof n !== 'number') {
    n = parseInt(n, 10);
  }
  if (!Number.isInteger(n) || !Number.isFinite(n) || Number.isNaN(n)) {
    return null;
  }
  return n;
}
