<div class="tizen-apps-dialog-table">
  <div class="tizen-apps-dialog-table__wrapper">
    <table mat-table [dataSource]="dataSource">

      <!-- Version Column -->
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>Version</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <mat-radio-button
              [disabled]="element.is_current"
              (change)="selectNewVersion($event)"
              [class.hidden-radio-button]="element.is_current"
              [value]="element.version"
              name="selection"></mat-radio-button>
            {{ element.version }}
            <mat-chip class="current-chip" *ngIf="element.is_current">{{ deployingMode ? 'Deploying...' : 'Current' }}</mat-chip>
            <!-- In case there is a control plane version mismatch -->
            <app-outlined-icon
              fontSet="material-symbols-outlined"
              icon="warning"
              [matTooltip]="getControlPlaneUpgradeTooltip(element)"
              *ngIf="requiresControlPlaneUpgrade(element)"
            ></app-outlined-icon>
            <!-- ToDo: Define progress bar logic -->
            <!-- <div
              *ngIf="false"
              class="tizen-apps-dialog-table__progress-bar">
              <div class="tizen-apps-dialog-table__progress-bar__indicator"
                [ngStyle]="{ 'width': deployProgress() + '%' }"
              >
                <div class="tizen-apps-dialog-table__progress-bar__text">
                  {{ deployProgress() }}%
                </div>
              </div>
            </div> -->
          </div>
        </td>
      </ng-container>

      <!-- Displays Column -->
      <ng-container matColumnDef="total_displays">
        <th mat-header-cell *matHeaderCellDef>Displays</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex gap-2 align-items-center" [class.outdated-displays-info]="!element.is_current && element.total_displays > 0">
            {{ element.total_displays || '--' }}
            <!-- "See Unaffected Displays" button will be visible here -->
            <span class="outdated-displays-info__icon-container" *ngIf="!element.is_current && element.total_displays > 0">
              <mat-icon 
                class="material-symbols-outlined" 
                fontIcon="info"
                cdkOverlayOrigin 
                #overlayOrigin="cdkOverlayOrigin"
                (click)="element.showOverlay = !element.showOverlay"
              ></mat-icon>

              <ng-template 
                cdkConnectedOverlay 
                [cdkConnectedOverlayOffsetX]="33"
                [cdkConnectedOverlayOffsetY]="-70" 
                [cdkConnectedOverlayOrigin]="overlayOrigin"
                [cdkConnectedOverlayOpen]="element.showOverlay" 
                [cdkConnectedOverlayHasBackdrop]="element.showOverlay"
                [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop-cs'" 
                (backdropClick)="element.showOverlay = !element.showOverlay"
              >
                <div class="outdated-displays-tooltip">
                  <div class="outdated-displays-tooltip__text">These displays are unable to be updated to the new version.</div>
                  <div class="outdated-displays-tooltip__button">
                    <button
                      mat-stroked-button
                      color="basic"
                      (click)="onShowOutdatedDisplaysInfo(element.version)"
                    >
                      DISPLAY INFO
                    </button>
                  </div>
                </div>
              </ng-template>
            </span>

          </div>
        </td>
      </ng-container>

      <!-- Release Date Column -->
      <ng-container matColumnDef="release_date">
        <th mat-header-cell *matHeaderCellDef>Release date</th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.release_date | dateFormat : "date" }}
            {{ element.release_date | dateFormat : "time" }}
          </div>
        </td>
      </ng-container>

      <!-- Release Notes Column -->
      <ng-container matColumnDef="release_notes">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <!-- <button mat-icon-button color="basic" (click)="onClickReleaseNotesButton(element)">
            <img src="../../../../../assets/images/release-notes.svg" alt="">
          </button> -->
          <button *ngIf="element.is_current" (click)="downloadFile()" mat-icon-button color="basic">
            <mat-icon style="color: rgba(255,255,255,0.56);" class="material-symbols-outlined"
              fontIcon="download"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.current-version-row]="selectedAppVersion ? row.version === selectedAppVersion : row.is_current"></tr>
    </table>
  </div>
  <div
    class="tizen-apps-dialog-table__paginator"
  >
    <mat-paginator #paginator class="my-1" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" aria-label="Select page"></mat-paginator>
  </div>
</div>
