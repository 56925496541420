import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import parseInteger from '../utils/parseInteger';

export enum Feature {
  None = 0,
  EPG = 1,
  NextFeature = 2
}

@Injectable({
  providedIn: 'root'
})
export class FeaturesService {
  private readonly localStorageKey = 'features';
  /**
   * User-enabled features
   */
  public features: BehaviorSubject<Feature>;
  private epgFeature: BehaviorSubject<boolean>;
  public epgFeatureObs: Observable<boolean>;

  constructor() {
    const n = this.decode();
    let isEPGEnabled = false;
    let initialFeatureFlags: Feature = Feature.None;
    if (n !== null) {
      if (n & Feature.EPG) {
        isEPGEnabled = true;
      }
      initialFeatureFlags = n;
    }
    this.features = new BehaviorSubject<Feature>(initialFeatureFlags);
    this.epgFeature = new BehaviorSubject<boolean>(isEPGEnabled);
    this.epgFeatureObs = this.epgFeature.asObservable();
  }

  enable(feature: Feature, isEnabled: boolean) {
    if (feature & Feature.EPG) {
      if (isEnabled) {
        this.features.next(this.features.value | Feature.EPG);
      } else {
        this.features.next(this.features.value & ~Feature.EPG);
      }
      this.epgFeature.next(isEnabled);
    }
    this.save();
  }

  public parse() {
    const n = this.features.value;
    let isEPGEnabled = false;
    if (n !== null) {
      if (n & Feature.EPG) {
        isEPGEnabled = true;
      }
    }
    return {
      isEPGEnabled
    };
  }

  /**
   * Save current enabled feature flags using Local Storage API
   */
  public save() {
    localStorage.setItem(
      this.localStorageKey,
      this.features.value.toString(10)
    );
  }

  /**
   * Decode feature flags from the value stored through the Local Storage API. If no value is
   * stored, or the value is not a valid integer, return null.
   * @returns decoded feature flags from Local Storage API
   */
  public decode(): Feature {
    let n = Feature.None;
    const parsed = parseInteger(
      localStorage.getItem(this.localStorageKey) ?? NaN
    );
    if (parsed !== null) {
      /**
       * Make sure that only valid flags are added
       */
      for (const flag of [Feature.None, Feature.EPG, Feature.NextFeature]) {
        if (parsed & flag) {
          n |= flag;
        }
      }
    }
    return n;
  }
}
