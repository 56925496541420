<mat-toolbar class="iptv-header">
  <!-- <button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button> -->
  <img
    src="../../../../assets/images/perform-havrion-logo.png"
    alt="Perform Logo"
    border="0"
  /><!-- <span>PERFORM</span> -->
  <span class="example-spacer"></span>
  <!-- <button
    class="example-icon favorite-icon"
    aria-label="Example icon-button with heart icon"
  >
    <mat-icon>favorite</mat-icon>
  </button> -->
  <!-- <button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button> -->
  <div style="width: 100%">
    <ul *ngIf="userProfile" class="nav navbar-nav" style="float: right">
      <!-- <li>
            <button mat-button aria-label="Menu with option to Logout" style="line-height: 50px;">
                <ng-template>
                 hey
                </ng-template>
                &nbsp;
                <span class="d-none d-md-inline-block">Test Name</span>
            </button>
    </li> -->
      <!-- <li>
        <button class="btn btn-primary--iptv" (click)="logOut()">Log Out</button>
    </li> -->
      <li class="header-horizontal-divider">
        <button
          mat-button
          aria-label="Menu with option to Logout"
          [matMenuTriggerFor]="accountMenu"
          class="header-menu-button"
          data-testId="header-menu-button"
          disableRipple
        >
          <!-- <ng-template>
            <mat-icon>account_circle</mat-icon>
          </ng-template> -->
          <mat-spinner *ngIf="enableSpinner" [diameter]="15"></mat-spinner>
          &nbsp;
          <span class="d-none d-md-inline-block">{{
            userProfile?.full_name
          }}</span>
          <mat-icon class="d-none d-md-inline-block"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon class="d-sm-inline-block d-md-none">more_vert</mat-icon>
        </button>
        <mat-menu #accountMenu="matMenu">
          <button
            *ngIf="this.userProfile?.accounts?.length > 1"
            mat-menu-item
            [matMenuTriggerFor]="accountsMenu"
            data-testId="header-accounts-menu-button"
          >
            <mat-icon>group</mat-icon>
            <span>Accounts</span>
          </button>

          <div
            class="beta-features-menu"
            *ngIf="this.shouldShowBetaFeaturesOption"
            (click)="$event.stopPropagation()"
          >
            <hr
              *ngIf="this.userProfile?.accounts?.length > 1"
              class="beta-features-menu__top-divider"
            />
            <div class="beta-features-menu__title">
              <mat-icon class="material-symbols-outlined">rocket</mat-icon>
              <span>Beta Features</span>
            </div>
            <div class="beta-features-menu__toggle">
              <mat-slide-toggle
                (change)="toggleEPG($event)"
                [checked]="this.enabledFeatures.epg"
                data-testId="epg-toggle-button"
              >
                <span>EPG</span>
              </mat-slide-toggle>
            </div>
            <hr class="beta-features-menu__bottom-divider" />
          </div>

          <button mat-menu-item class="">
            <mat-icon class="material-symbols-outlined">help</mat-icon>
            <span>User Guide</span>
          </button>
          <button
            *ngIf="allowPasswordReset"
            mat-menu-item
            class=""
            (click)="resetPassword()"
          >
            <mat-icon class="material-symbols-outlined">lock_open</mat-icon>
            <span>Reset Password</span>
          </button>
          <button mat-menu-item class="" (click)="logOut()">
            <mat-icon>logout</mat-icon>
            <span>Log Out</span>
          </button>
        </mat-menu>
      </li>
    </ul>
    <mat-menu #accountsMenu="matMenu" class="accounts-menu">
      <div
        class="account-item"
        data-testId="header-accounts-menu-item"
        [ngClass]="{
          'selected-menu-item': item.id == this.userProfile?.account_id
        }"
        *ngFor="let item of sortedAccounts"
      >
        <button
          mat-menu-item
          (click)="changeAccount(item.id)"
          [disabled]="item.id == this.userProfile?.account_id"
          data-testId="header-accounts-menu-item-name"
        >
          {{ item.name }}
          <mat-icon
            *ngIf="item.id == this.userProfile?.account_id"
            aria-hidden="false"
            aria-label="check icon"
            class="icon-logo-checked"
            fontIcon="check_small"
          ></mat-icon>
        </button>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
