import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService, AvailableFeature } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class TagsGuard implements CanActivate {

  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.userProfileService.userProfile) {
      return this.userProfileService.getUserProfile().then(() => {
        return this.checkTagsFeatures();
      });
    } else {
      return this.checkTagsFeatures();
    }
  }

  private checkTagsFeatures(): boolean {
    const TagManagerContentFeature = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerContent);
    const TagManagerLocationsFeature = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerLocations);
    const TagManagerSyncGroupsFeature = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerSyncGroups);

    if (TagManagerContentFeature || TagManagerLocationsFeature || TagManagerSyncGroupsFeature) {
      return true;
    }

    this.router.navigate(['/404']);
    return false;
  }
}
