import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigurationsService } from 'src/app/core/services/configurations.service';
import { DpGroupTreeService } from 'src/app/core/services/dp-group-tree.service';
import { ConfigurationsListResponse } from 'src/app/core/interfaces/configurationsListResponse';
import { UserProfileService, AvailableFeature } from 'src/app/core/services/user-profile.service';
import { FeaturesService } from 'src/app/core/services/features.service';
import { Subscription } from 'rxjs';
import { DialogService } from '../dialog/dialog.service';
import { TizenAppsDialogComponent } from 'src/app/features/secured-skeleton/tizen-apps/tizen-apps-dialog/tizen-apps-dialog.component';
import { SspAppComponent } from 'src/app/features/secured-skeleton/ssp-app/ssp-app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  @Input('data') set configListDataSet(value: ConfigurationsListResponse[]) {
    if (value) {
      this.configList = value;
    }
  }

  get configListDataSet() {
    return this.configList;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private configurationsService: ConfigurationsService,
    private dpGroupTreeService: DpGroupTreeService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private userProfileService: UserProfileService,
    private featuresService: FeaturesService,
    private router: Router,
  ) {
    this.featuresSub = this.featuresService.epgFeatureObs.subscribe(
      (isEnabled) => {
        this.epgEnabled = isEnabled;
      }
    );
  }

  private featuresSub: Subscription;

  configList: ConfigurationsListResponse[] = [];
  searchOpen: boolean = false;
  addDisabled = false;
  showContentPlaylist: boolean = false;
  newTizenAppsDialogEnabled = false;
  epgEnabled = false;
  showWebPlayer: boolean = false;
  showManagement: boolean = true;
  showTags: boolean = false;
  managementPanelOpenState = false;

  showNewTizenAppsDialog: boolean = false;

  refeshConfigList() {
    this.configurationsService.sendConfigurationSubs({
      channel: 'ManagementHeaderComponent - ManagementComponent',
      broadcastID: 'ManagementHeaderComponent',
      payload: {
        refresh: true
      }
    });
  }

  clickEvent() {
    this.searchOpen = !this.searchOpen;
  }

  async ngOnInit() {
    // Web Player
    this.showWebPlayer = this.userProfileService.isFeatureEnabled(AvailableFeature.WebPlayer);

    // Tags Management
    const showTagManagerContent = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerContent);
    const showTagManagerLocations = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerLocations);
    const showTagManagerSyncGroups = this.userProfileService.isFeatureEnabled(AvailableFeature.TagManagerSyncGroups);

    if (showTagManagerContent || showTagManagerLocations || showTagManagerSyncGroups) {
      this.showTags = true;
    }

    // Device Management
    this.showManagement = this.userProfileService.isFeatureEnabled(AvailableFeature.DeviceManagement);

    // Comment this to restrict domains for content placement
    this.showContentPlaylist = true;

    // Tizen Apps v2 Dialog
    this.newTizenAppsDialogEnabled = this.userProfileService.isFeatureEnabled(AvailableFeature.TizenAppV2);

    if(this.router.url.includes('secured/devices') || this.router.url.includes('secured/tags')) {
      setTimeout(() => {
        this.managementPanelOpenState = true;
      }, 1000);
    }
  }

  // async getUserProfile() {
  //   await this.userProfileService.getUserProfile();
  //   // this.userProfile = this.userProfileService.userProfile;
  // }

  async getDisplaysGroups(content: string) {
    await this.dpGroupTreeService.getDeviceTree();
    if (this.dpGroupTreeService.dispGrpTree) {
      // this.openDialog(content, this.dpGroupTreeService.dispGrpTree);
      this.openDialog(content);
    }
  }

  openDialog(content: string): void {
    if (content == 'tizen-app') {
      if (this.newTizenAppsDialogEnabled) {
        this.openTizenAppsDialog();
      } else {
        this.openLegacyTizenAppsDialog();
      }
    }
  }

  openLegacyTizenAppsDialog() {
    this.dialogService.openDialog({
      content: SspAppComponent,
      title: 'List of Available Apps',
      data: {}
    });
  }

  openTizenAppsDialog() {
    this.dialogService.openDialog(
      {
        content: TizenAppsDialogComponent,
        title: 'List of Available Apps',
        data: {}
      },
      null,
      '40vw',
      {
        autoFocus: false,
        panelClass: 'tizen-apps-dialog-panel'
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000
    });
  }

  ngOnDestroy() {
    this.featuresSub.unsubscribe();
  }
}
