import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  EnvironmentInjector,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IOpenDialogOptions, IDialog, IDialogOptions } from './dialog.service';

@Directive({
  selector: '[appExtendedProgramGuideDialogContent]'
})
export class DialogContentDirective implements OnDestroy {
  @Input() public content: Type<IDialog>;
  @Input() public data: unknown;
  @Input() public dialogOptions: IDialogOptions;
  @Output() public onClose = new EventEmitter<unknown>();

  public contentInstance: IDialog | null = null;

  public constructor(
    private environmentInjector: EnvironmentInjector,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  public ngOnDestroy(): void {
    this.viewContainerRef.clear();
    this.contentInstance = null;
  }

  public createComponent() {
    if (this.contentInstance) {
      return;
    }
    const componentRef = this.viewContainerRef.createComponent<IDialog>(
      this.content,
      {
        environmentInjector: this.environmentInjector
      }
    );
    const dialogContents = componentRef.instance;
    dialogContents.dialogOptions = this.dialogOptions;
    dialogContents.data = this.data;
    dialogContents.close = this.closeWithValue;
    this.contentInstance = dialogContents;
    this.changeDetectorRef.detectChanges();
  }

  public onDelete() {
    if (this.contentInstance && this.contentInstance.onDelete) {
      this.contentInstance.onDelete();
    }
  }

  public onCancel() {
    if (this.contentInstance && this.contentInstance.onCancel) {
      this.contentInstance.onCancel();
    }
  }

  public onConfirm() {
    if (this.contentInstance && this.contentInstance.onConfirm) {
      this.contentInstance.onConfirm();
    }
  }

  private closeWithValue = (value: unknown) => {
    this.onClose.emit(value);
  };
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit {
  @ViewChild(DialogContentDirective) public content: DialogContentDirective;

  public dialogOptions: IDialogOptions = {
    saveButton: {
      disable: false,
      show: true
    },
    cancelButton: {
      disable: false,
      show: true,
      position: 'right'
    },
    deleteButton: {
      disable: false,
      show: true
    },
    loading: false
  };

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IOpenDialogOptions<IDialog>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private matDialogRef: MatDialogRef<DialogComponent>
  ) {}

  public ngAfterViewInit(): void {
    this.content.createComponent();
    this.changeDetectorRef.detectChanges();
  }

  public onDelete() {
    this.content.onDelete();
  }

  public onCancel() {
    this.content.onCancel();
  }

  public onConfirm() {
    this.content.onConfirm();
  }

  /**
   * When the `close` method is called by the dialog content, this method is called.
   */
  public onClose(value: unknown) {
    this.matDialogRef.close(value);
  }

  get deleteButtonLabel(): string {
    return this.dialogOptions.deleteButton.label ?? 'Delete';
  }

  get saveButtonLabel(): string {
    return this.dialogOptions.saveButton.label ?? 'Save';
  }

  get cancelButtonLabel(): string {
    return this.dialogOptions.cancelButton.label ?? 'Cancel';
  }
}
