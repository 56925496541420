import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { EULAValues } from '../interfaces/usersResponse';
import { UserProfileService } from '../services/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard implements CanActivate {
  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      let eulaRes: EULAValues = this.userProfileService.eulaRes;
      
    if (!eulaRes) {
      return this.getUserProfile().then((res) => {
        return res;
      });
    } 

    return true;
  }

  async getUserProfile(): Promise<boolean> {
    await this.userProfileService.getUserProfile();
    let eulaRes: EULAValues = this.userProfileService.eulaRes;
    
    if (eulaRes) {
      return true;
    } 

    return false;
  }
}
