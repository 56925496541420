import { Injectable, Injector, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';

export interface IDialog<Input = unknown, Output = unknown> {
  /**
   * close the dialog
   */
  close(response: Output): void;
  data: Input;
  dialogOptions: IDialogOptions;
  onCancel(): void;
  onDelete(): void;
  onConfirm(): void;
}

export interface IDialogButtonState {
  show: boolean;
  disable: boolean;
  label?: string;
  position?: 'left' | 'right';
  tooltip?: string;
}

export interface IDialogOptions {
  deleteButton: IDialogButtonState;
  cancelButton: IDialogButtonState;
  saveButton: IDialogButtonState;
  loading: boolean;
}

export type IDialogResult<T> = T extends IDialog<any, infer U> ? U : never;

export type IOpenDialogOptions<T> = T extends IDialog<infer U>
  ? {
      title: string;
      content: Type<T>;
      data: U;
      /**
       * If true, the dialog will not have a frame
       */
      unframed?: boolean;
    }
  : never;

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(
    private readonly injector: Injector,
    private readonly matDialog: MatDialog
  ) {}

  /**
   * Opens a dialog using `MatDialog` with the given options. A `content` property must be provided
   * to specify the component to be used as the dialog content. The component must implement the
   * `IDialog` interface.
   *
   * The `data` property of the component will be the same `data` property of the options.
   *
   * This method will automatically infer the type needed for correctly calling this method
   * as soon as the `content` property is provided.
   *
   * @param options Options for the dialog
   * @param width Width of the dialog
   * @param maxWidth Maximum width of the dialog
   * @param matDialogConfig Material dialog configuration
   * @returns {MatDialogRef<DialogComponent, IDialogResult<T>>} The dialog reference
   */
  public openDialog<T>(
    options: IOpenDialogOptions<T>,
    width: string | undefined = undefined,
    maxWidth = '90vw',
    matDialogConfig?: MatDialogConfig,
  ) {
    return this.matDialog.open<
      DialogComponent,
      IOpenDialogOptions<T>,
      IDialogResult<T>
    >(DialogComponent, {
      data: options,
      width: width,
      /**
       * Force the user to close the dialog by clicking on the buttons to avoid
       * `afterClosed()` returning undefined.
       */
      disableClose: true,
      maxWidth: maxWidth,
      injector: this.injector,
      panelClass: "app-dialog-panel-class",
      ...matDialogConfig
    });
  }
}
