import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppsTizenResponse } from 'src/app/core/interfaces/appsTizenResponse';
import { TizenAppService } from 'src/app/core/services/tizenApp.service';
import { IDialog, IDialogOptions } from 'src/app/shared/secured-shared/dialog/dialog.service';

@Component({
  selector: 'app-ssp-app',
  templateUrl: './ssp-app.component.html',
  styleUrls: ['./ssp-app.component.scss'],
})
export class SspAppComponent implements OnInit, IDialog {
  constructor(
    private tizenAppService: TizenAppService,
    private _snackBar: MatSnackBar
  ) {}

  public readonly data: {};
  public readonly dialogOptions: IDialogOptions;

  displayedColumns: string[] = ['name', 'release_date', 'button'];

  previousTizenApps;
  latestTizenApp = [];

  downloadFile(url: string) {
    window.open(url);
  }

  setPrevLatApps(apps: AppsTizenResponse[]) {
    this.latestTizenApp = [apps[0]];
    apps.splice(0, 1);
    this.previousTizenApps = apps;
    console.log('this.previousTizenApps', this.previousTizenApps);
  }

  ngOnInit(): void {
    this.dialogOptions.saveButton.show = false;
    this.dialogOptions.deleteButton.show = false;
    this.dialogOptions.cancelButton.show = true;
    this.dialogOptions.cancelButton.label = 'Close';

    this.tizenAppService.getAppsTizen().subscribe({
      next: (res: AppsTizenResponse[]) => {
        if (res?.length > 0) {
          this.setPrevLatApps(res);
        }
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      },
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  public close: (response: {}) => void;

  public onCancel(): void {
    this.close({});
  }

  public onDelete(): void {}

  public onConfirm(): void {}
}
