import { Component, Input, OnInit } from '@angular/core';

/** Use to show a full loading screen placeholder */
@Component({
  selector: 'loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  /** The size of the text and loading indicator to be shown in PX */
  @Input()
  fontSize = 16

  /** The loading text to be displayed */
  @Input()
  loadingText = 'Loading'

  /** True to three horizontal dots next to the [loadingText] */
  @Input()
  showEllipsis = true

  constructor() { }

  ngOnInit(): void {
  }

}
