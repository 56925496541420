<!-- <div class="m-4">
    <h3>Havrion Perform</h3>
    <button *ngIf="!handleRedirect" class="btn btn-primary" (click)="logInPressed('preform')">Access Now</button>
    <p *ngIf="handleRedirect">Redirecting...</p>
</div> -->
<!-- <div *ngIf="!handleRedirect" class="m-4">
    <h3>Havrion Protect</h3>
    <button *ngIf="!handleRedirectP" class="btn btn-primary" (click)="logInPressed('protect')">Access Now</button>
    <p *ngIf="handleRedirectP">Redirecting...</p>
</div> -->
<div
  *ngIf="hideLogin"
  style="position: absolute; width: 100%; text-align: center; margin-top: 10%"
>
  {{ hideLogin && handleRedirect ?  'Redirecting....' : 'Loading Profile ...'}}

</div>
<div class="container__login" *ngIf="!hideLogin">
  <div class="row g-0">
    <div class="info col-md-8 col-xl-9 d-none d-md-block align-items-center">
      <nav class="info__navbar">
        <div class="d-flex justify-content-around">
          <!-- <a [href]="thermalProtect" target="_blank">THERMAL DETECT</a>
                    <a [href]="contact">CONTACT</a> -->
        </div>
      </nav>
      <img
        class="info__havrion-logo"
        src="../../../../assets/images/Havrion_Solutions_Logo.png"
        alt="Havrion Solutions Logo"
      />
    </div>

    <div class="login col-md-4 col-xl-3">
      <div class="login__top">
        <div class="d-flex justify-content-center">
          <img
            class="login__logo"
            src="../../../../assets/images/HHub_logo.png"
            alt="Havrion Hub Logo - Please log in"
          />
        </div>
        <div class="d-flex justify-content-center">          
          <p class="login__welcome"></p>
        </div>
        <div class="d-flex justify-content-center">
          <button
            *ngIf="!redirectLogging"
            data-testId="access-button"
            class="btn btn-primary login__btn"
            (click)="logInPressed('perform')"
          >
            ACCESS NOW
          </button>
          <span style="color: var(--iptv-brand-green)" *ngIf="redirectLogging">Redirecting ...</span>
          <!-- <p *ngIf="handleRedirect">Redirecting...</p> -->
        </div>
        <div class="d-flex justify-content-center">
          <!-- <a [href]="signUpAuthority" class="login__registration">New user? Click here</a> -->
          <a (click)="newUser()" class="login__registration" data-testId="new-user-button"
            >New user?</a
          >
        </div>
      </div>

      <div class="login__swipper">
        <img src="../../../../assets/images/logo_perform.svg" border="0" />
      </div>
    </div>
    <!-- <hub-footer></hub-footer> -->
    <!-- <app-footer></app-footer> -->
    <div class="hh-footer">
      <div class="hh-footer__logo">
        <img
          src="../../../../assets/images/Havrion-Logo-grey.png"
          alt="Havrion logo"
        />
      </div>
      <div class="hh-footer__links">
        2900 Monarch Lakes Blvd, Miramar, Florida 33027
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="tel:+1-844-811-2486">+1 844.811.2486</a>
        &nbsp;&nbsp;|&nbsp;&nbsp; <a [href]="contact">Contact</a>
      </div>
      <div class="hh-footer__bottom">
        Copyright ©2023 Havrion LLC All rights reserved &nbsp;&nbsp;|
        &nbsp;&nbsp;<a data-testId="privacy-policy-button" href="/privacy-policy">Privacy Policy</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a data-testId="terms-of-use-button" href="/terms-of-use">Terms of Use</a>&nbsp;&nbsp;|&nbsp;&nbsp;
        <a [href]="protectLink" target="_blank">Havrion Protect</a>
      </div>
    </div>
  </div>
</div>
