import {
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IFailedDeployDisplay } from 'src/app/core/services/tizenApp.service';

@Component({
  selector: 'app-unaffected-displays-table',
  templateUrl: './unaffected-displays-table.component.html',
  styleUrls: ['./unaffected-displays-table.component.scss']
})
export class PendingDisplaysTableComponent implements OnInit {
  @Input() set displays(value: IFailedDeployDisplay[]) {
    this._displays = value;
    this.dataSource.data = value;
  };
  @Input() loading: boolean = false;

  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  constructor() { }

  _displays: IFailedDeployDisplay[] = [];

  dataSource = new MatTableDataSource<IFailedDeployDisplay>();
  displayedColumns: string[] = ['name', 'serial', 'code', 'server'];

  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50];
  length = 0;
  pageEvent: PageEvent;

  ngOnInit(): void {
    setTimeout(() => {
      this.dataSource.data = this._displays;
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  handlePageEvent(event: PageEvent) {
    this.pageEvent = event;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }
}
