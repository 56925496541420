<div class="terms__container">
    <header class="terms__header d-flex align-items-center">
      <a href="/login"
        ><img
          class="terms__header-logo ml-4"
          src="../../../../assets/images/perform-logo.png"
          alt=""
      /></a>
      <div class="terms__name ml-auto d-flex align-items-center"></div>
    </header>
    <div class="terms__box" style="min-height: 800px">
      <div class="terms__box-head d-flex align-items-center">
        <p class="terms__title" data-test-id="privacy-policy-header">HAVRION PERFORM <span>PRIVACY POLICY</span></p>
      </div>
  
      <div
        class="terms__content"
        data-test-id="privacy-policy-content"
        appCustomScroll
        #appScrollElement="appCustomScroll"
        #scroll
        [innerHTML]="termsText"
        style="max-height: 60vh; min-height: 500px"
      ></div>
      <div class="d-flex justify-content-center">
        <button data-test-id="return-button" mat-button class="btn__continue" (click)="return()">Go back</button>
      </div>
      <br /><br />
    </div>
  </div>
  <app-footer style="width: 100%; bottom: 0"></app-footer>
  