import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';

import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './core/components/login/login.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { SecuredTokenInterceptor } from './core/interceptor/secured-token.interceptor';
import { SecuredSharedModule } from './shared/secured-shared/secured-shared.module';
import { BlobErrorHttpInterceptor } from './core/interceptor/blobErrorHttp.interceptor';
import { ExtendedProgramGuideModule } from './features/secured-skeleton/extended-program-guide/extended-program-guide.module';
import { SspAppModule } from 'src/app/features/secured-skeleton/ssp-app/ssp-app.module';
import { TizenAppsModule } from './features/secured-skeleton/tizen-apps/tizen-apps.module';

// Configuration of the MSAL module
const msalModule = MsalModule.forRoot(
  new PublicClientApplication({
    auth: {
      clientId: environment.azureB2C.clientId,
      authority: `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com/${environment.azureB2C.userFlows.signIn}`,
      redirectUri: environment.azureB2C.redirectUri.perform,
      postLogoutRedirectUri: environment.azureB2C.postLogoutRedirectUri,
      knownAuthorities: [
        `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com`,
        ...environment.azureB2C.otherKnownAutorities // Add other authorities in case it is necessary
      ]
    },
    cache: {
      cacheLocation: environment.azureB2C.cacheLocation,
      storeAuthStateInCookie: environment.azureB2C.storeAuthStateInCookie
    }
  }),
  environment.azureB2C.guardConfig,
  environment.azureB2C.interceptorConfig
);

@NgModule({
  declarations: [AppComponent, LoginComponent, PageNotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    msalModule,
    BrowserAnimationsModule,
    ExtendedProgramGuideModule,
    SspAppModule,
    SecuredSharedModule,
    TizenAppsModule
  ],
  providers: [
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecuredTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
