import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date | string, format: 'date' | 'time'): string {
    if (!value) return '';

    const date = typeof value === 'string' ? new Date(value) : value;
    switch (format) {
      case 'date': {
        const options: Intl.DateTimeFormatOptions = {
          timeZone: 'UTC',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        const result = new Intl.DateTimeFormat('en-US', options).format(date);
        if (result.length >= 15) {
          options.month = 'short';
        }
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }
      case 'time':
        return new Intl.DateTimeFormat('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }).format(date);
      default:
        throw new Error('Please define a format for the `dateFormat` pipe');
    }
  }
}
