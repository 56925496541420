import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Injectable()
export class SecuredTokenInterceptor implements HttpInterceptor {
  private get idToken(): string | null {
    const account = this.authService.instance.getAllAccounts()?.[0];
    // console.log('account', account);
    if (!account) {
      return null;
    }

    const [homeAccountId, environment, audience, tid] = [
      account.homeAccountId,
      account.environment,
      account.idTokenClaims?.['aud'],
      account.idTokenClaims?.['tid'],
    ];

    if (!homeAccountId || !environment || !audience) {
      return null;
    }

    let tokenKey;

    if (tid) {
      tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}-${tid}---`;
    } else {
      tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}----`;
    }

    // const tokenKey = `${homeAccountId}-${environment}-idtoken-${audience}-${tid}---`;
    // console.log('tokenKey', tokenKey);

    const idTokenData = JSON.parse(sessionStorage.getItem(tokenKey));

    // console.log('idTokenData', idTokenData);

    // console.log('jwt token', idTokenData?.['secret']);

    return idTokenData?.['secret'] ?? null;
  }

  constructor(
    private authService: MsalService,
    private router: Router,
    private injector: Injector,
    private _snackBar: MatSnackBar
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const jwt = this.idToken;
    // console.log('jwt', this.idToken);

    if (!jwt) {
      return next.handle(request);
    }

    const authRequest = request.clone({
      setHeaders: {
        Authorization: request.headers['Authorization'] ?? `Bearer ${jwt}`,
      },
    });

    // console.log('authRequest', JSON.stringify(authRequest.headers));

    return next.handle(authRequest).pipe(
      catchError((err: HttpErrorResponse, caught: Observable<any>) => {
        let error = { ...err };
        // console.log('error interceptor 1', error);
        if (err.status === 401) {
          const userProfileService = this.injector.get(UserProfileService);
          const logoutReason = userProfileService.userProfile
            ? 'expired'
            : 'invalid';

          // Send the user to the login page with a message about the logout reason
          this.authService.logout({
            postLogoutRedirectUri: `/login?session=${logoutReason}`
          });

          error['error'] =
            'Your session has expired. You are being redirected to the login page.';
          return throwError(() => error);
        } else if (err.status === 451) {
          error.message =
            'You must to accept the Terms of Use and Privacy Policy';
          if (!err.error.has_accepted_terms_and_conditions) {
            this.router.navigate(['/eula/terms-of-use']);
          } else if (!err.error.has_accepted_privacy_policy) {
            this.router.navigate(['/eula/privacy-policy']);
          }
          return throwError(() => error);
        } else if (err.status >= 500) {
          error.message =
            'The system had an internal error. If you do not see the expected results, contact support.';
          return throwError(() => error);
        } else {
          // console.log('error interceptor', error);
          
          return throwError(() => error);
        }
      })
    );
  }
}
