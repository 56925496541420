import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  protectLink = 'https://hub.havrion.com/login';
  contact = 'mailto:info@havrion.com';
 
  constructor() { }

  ngOnInit(): void {
  }

}
