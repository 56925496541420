<ng-container *ngIf="data.unframed; else framed">
  <ng-container *ngTemplateOutlet="unframed"></ng-container>
</ng-container>

<ng-template #unframed>
  <ng-template
    appExtendedProgramGuideDialogContent
    [dialogOptions]="dialogOptions"
    [content]="data.content"
    [data]="data.data"
    (onClose)="onClose($event)"
  ></ng-template>
</ng-template>

<ng-template #framed>
  <div class="iptv-dialog epg-dialog" data-testId="epg-dialog-container">
    <div class="iptv-dialog__header iptv-dialog__header--tabs epg-dialog__header">
      <h1 mat-dialog-title data-testId="epg-dialog-title">
        {{ data.title }}
      </h1>
    </div>
    <mat-progress-bar
      *ngIf="dialogOptions.loading"
      class="epg-dialog__loading-indicator"
      data-testId="epg-dialog-loading-indicator"
      mode="indeterminate">
    </mat-progress-bar>
    <div mat-dialog-content class="epg-dialog__content" data-testId="epg-dialog-content">
      <ng-container *ngTemplateOutlet="unframed"></ng-container>
    </div>

    <div
      mat-dialog-actions
      class="dialog-buttons epg-dialog__actions d-flex flex-row"
    >
      <div class="flex-fill d-flex align-items-start">
        <button
          mat-stroked-button
          color="warn"
          [disabled]="dialogOptions.deleteButton.disable"
          [matTooltip]="dialogOptions.deleteButton.tooltip || ''"
          *ngIf="dialogOptions.deleteButton.show"
          data-testid="epg-dialog-button-delete"
          (click)="onDelete()"
        >
          {{ deleteButtonLabel }}
        </button>
        <!-- Remove the `epg-` prefix from the data-testId attribute -->
        <button
          mat-stroked-button
          color="basic"
          [disabled]="dialogOptions.cancelButton.disable"
          [matTooltip]="dialogOptions.cancelButton.tooltip || ''"
          *ngIf="dialogOptions.cancelButton.show && dialogOptions.cancelButton.position === 'left'"
          data-testid="epg-dialog-button-cancel"
          (click)="onCancel()"
        >
          {{ cancelButtonLabel }}
        </button>
      </div>

      <!-- Remove the `epg-` prefix from the data-testId attribute -->
      <button
        mat-stroked-button
        color="basic"
        [disabled]="dialogOptions.cancelButton.disable"
        [matTooltip]="dialogOptions.cancelButton.tooltip || ''"
        *ngIf="dialogOptions.cancelButton.show && dialogOptions.cancelButton.position === 'right'"
        data-testid="epg-dialog-button-cancel"
        (click)="onCancel()"
      >
        {{ cancelButtonLabel }}
      </button>

      <button
        mat-raised-button
        color="accent"
        [disabled]="dialogOptions.saveButton.disable"
        [matTooltip]="dialogOptions.saveButton.tooltip || ''"
        data-testid="epg-dialog-button-save"
        *ngIf="dialogOptions.saveButton.show"
        (click)="onConfirm()"
      >
        {{ saveButtonLabel }}
      </button>
    </div>
  </div>
</ng-template>
