/**
 * Utility class for handling Tizen app versions.
 */
export default class TizenAppsVersionUtil {
  static getVersionValue(version: string | number): number {
    return parseFloat(this.getVersionString(version));
  }

  static getVersionString(version: string | number): string {
    if(typeof version === 'number') {
      return version.toFixed(2);
    } else {
      return version;
    }
  }

  static compare(version1: string | number, version2: string | number): boolean {
    return this.getVersionValue(version1) === this.getVersionValue(version2);
  }

  static greaterThan(version1: string | number, version2: string | number): boolean {
    return this.getVersionValue(version1) > this.getVersionValue(version2);
  }

  static difference(version1: string | number, version2: string | number): number {
    return this.getVersionValue(version1) - this.getVersionValue(version2);
  }

  static lessThan(version1: string | number, version2: string | number): boolean {
    return this.getVersionValue(version1) < this.getVersionValue(version2);
  }
}
