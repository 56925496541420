<div class="pending-displays-table">
  <div class="pending-displays-table__wrapper">
    <table mat-table [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Display Name</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.name }}</div>
        </td>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serial">
        <th mat-header-cell *matHeaderCellDef>Serial Number</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.serial }}</div>
        </td>
      </ng-container>

      <!-- Mac Address Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Mac Address</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.code }}</div>
        </td>
      </ng-container>

      <!-- Managed by Column -->
      <ng-container matColumnDef="server">
        <th mat-header-cell *matHeaderCellDef>Managed by</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <img 
              style="width: 18px; margin-left: 27px;"
              *ngIf="!element.device_group_id" 
              src="../../../../../assets/images/perform_logo_P.png"
            >
            <img 
              style="width: 18px; margin-left: 27px;"
              *ngIf="element.device_group_id" 
              src="../../../../../assets/images/third-party-logo.svg"
            >
          </div>
        </td>
      </ng-container>

      <!-- Connection Column -->
      <ng-container matColumnDef="online">
        <th mat-header-cell *matHeaderCellDef>Connection</th>
        <td mat-cell *matCellDef="let element">
          <div>
            <!-- green badge if online and gray if offline-->
            <span 
              class="connection-badge" 
              [ngClass]="{ 'connection-badge--online': element.online, 'connection-badge--offline': !element.online }"
            >
            </span>
            {{ element.online ? 'Online' : 'Offline' }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5">No data matching the filter</td>
      </tr>
    </table>
  </div>
  <div
    class="pending-displays-table__paginator"
  >
    <mat-paginator #paginator class="my-1" (page)="handlePageEvent($event)" [length]="length" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" aria-label="Select page"></mat-paginator>
  </div>
</div>