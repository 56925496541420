import { Component,  OnInit } from '@angular/core';
import { IDialog, IDialogOptions } from 'src/app/shared/secured-shared/dialog/dialog.service';

export interface IQuickConfirmationDialogData {
  title: string;
  message: string;
  /**
   * The title of the action button
   */
  actionButtonTitle: string;
}

@Component({
  selector: 'app-quick-confirmation-dialog',
  templateUrl: './quick-confirmation-dialog.component.html',
  styleUrls: ['./quick-confirmation-dialog.component.scss']
})
export class QuickConfirmationDialogComponent implements OnInit, IDialog<IQuickConfirmationDialogData, boolean | null> {
  public close: (response: boolean) => void
  public data: IQuickConfirmationDialogData;
  public dialogOptions: IDialogOptions;

  /**
   * The dialog has no frame, so these functions will never be called
   */
  public onCancel(): void {}

  /**
   * The dialog has no frame, so these functions will never be called
   */
  public onDelete(): void {}

  /**
   * The dialog has no frame, so these functions will never be called
   */
  public onConfirm(): void {}

  /**
   * The dialog has no frame, so these functions will never be called
   */
  public constructor() { }

  public ngOnInit(): void {
    this.dialogOptions.deleteButton.show = false;
  }

}
