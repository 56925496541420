<div class="ssp-container">
  <img src="../../../../../assets/images/tizen_app.png" class="tizen-image" />

  <div class="latest-app-container" data-test-id="latest-app-container">
    Latest
    <table mat-table [dataSource]="latestTizenApp">
      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }} v{{ element.version }}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="release_date">
        <th mat-header-cell *matHeaderCellDef>Release date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.release_date | dateFormat : "date" }}
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="previous-download-btn">
            <button
              mat-stroked-button
              color="basic"
              data-test-id="download-iptv-app-button"
              class="btn_highlight"
              (click)="downloadFile(element.url)"
            >
              Download
              <mat-icon
                aria-hidden="false"
                aria-label="Example home icon"
                class="material-symbols-outlined"
                fontIcon="download"
              ></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div
    data-test-id="previous-iptv-apps-container"
    class="previous-app-container"
  >
    Previous Versions
    <div class="previous-apps">
      <table mat-table [dataSource]="previousTizenApps">
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element" data-test-id="iptv-app-name">
            {{ element.name }} v{{ element.version }}
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="release_date">
          <th mat-header-cell *matHeaderCellDef>Release date</th>
          <td
            mat-cell
            *matCellDef="let element"
            data-test-id="iptv-app-release-date"
          >
            {{ element.release_date | dateFormat : "date" }}
          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="previous-download-btn">
              <button
                data-test-id="download-iptv-app-button"
                mat-stroked-button
                color="basic"
                class="btn_highlight"
                (click)="downloadFile(element.url)"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="Example home icon"
                  class="material-symbols-outlined"
                  fontIcon="download"
                ></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr
          data-test-id="previous-iptv-app-release-row"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
</div>
