import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Content } from '../interfaces/playlistsResponse';

/** Non-specific utils to be used through the App */

/**
 * Logs messages / objects only for non-production environments
 * @param message The object to be logged
 */
export const devLog = (message: any) => {
  if (!environment.production) {
    console.log(message);
  }
};

export const compareFieldsToUpdate = (
  newData: Object,
  oldData: Object
): any => {
  let fields = {};
  for (let key in newData) {
    if (newData[key] != oldData[key]) {
      fields[key] = newData[key];
    }
  }
  return fields;
};

/*A function to compare if two arrays have the same elements regardless of their order and with nested object*/
export const equalsObjectCheck = (a, b) => {
  // Check if they point to the same instance
  if (a === b) return true;

  // Check if they are dates
  if (a instanceof Date && b instanceof Date)
    return a.getTime() === b.getTime();

  // Check if both of them are arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    // If they have different length
    if (a.length !== b.length) return false;

    // If they have the same length, check recursively for every element
    return a.every((e, i) => equalsObjectCheck(e, b[i]));
  }

  // If both of them are not null and their type is not an object
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b;

  // This means the elements are objects
  // If they are not the same type of objects
  if (a.prototype !== b.prototype) return false;

  // Check if both of the objects have the same number of keys
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;

  // Check recursively for every key in both
  return keys.every((k) => equalsObjectCheck(a[k], b[k]));
};

export const isIPAddress = (str: string): boolean => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      str
    )
  ) {
    return true;
  }
  return false;
};

export const handleError = (error: HttpErrorResponse) => {
  let payload = {
    code: 0,
    message: 'Unknown error!'
  };
  // console.log('error', error);
  let message = '';
  if (error.error.message) {
    message = error.error.message;
  } else if (error.error.error) {
    message = error.error.error;
  } else if (error.error) {
    try {
      message = JSON.parse(error.error).error;
    } catch {
      message = error.error;
    }
  } else {
    message = error.message;
  }
  if (error.error instanceof ErrorEvent) {
    // client-side error
    payload = {
      code: 0,
      message: message
    };
  } else {
    // server-side error
    payload = {
      code: error.status,
      message: message
    };
  }
  return throwError(() => payload);
};

export const pickKeys = <T, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  const picked: any = {};

  keys.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      picked[key] = obj[key];
    }
  });

  return picked;
};

export const eqSet = (xs: Set<any>, ys: Set<any>) => {
  return xs.size === ys.size && [...xs].every((x) => ys.has(x));
};

export const getDifference = (xs: Set<any>, ys: Set<any>) => {
  return new Set([...xs].filter((element) => !ys.has(element)));
};

export function diffSet<T>(xs: Set<T>, ys: Set<any>) {
  return new Set([...xs].filter((element) => !ys.has(element)));
}

export const humanFileSize = (size: number) => {
  const sizes = ['B', 'kB', 'MB', 'GB', 'TB'];
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const value = Number((size / Math.pow(1024, i)).toFixed(2));
  return `${value} ${sizes[i]}`;
};

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const sameUniqueContents = (
  newContents: Content[],
  oldContents: Content[]
) => {
  const uniqueNewPaths = new Set(newContents.map((c) => c.content_info.path));
  const uniqueOldPaths = new Set(oldContents.map((c) => c.content_info.path));
  return eqSet(uniqueNewPaths, uniqueOldPaths);
};

export const getTimeFromSeconds = (totalSeconds: number) => {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = totalSeconds - hours * 3600 - minutes * 60;
  return { hours: hours, minutes: minutes, seconds: Math.floor(seconds) };
};
