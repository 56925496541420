import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent, DialogContentDirective } from './dialog.component';
import { DialogService } from './dialog.service';
import { SecuredMaterialModule } from '../../secured-material/secured-material.module';

@NgModule({
  declarations: [DialogComponent, DialogContentDirective],
  providers: [DialogService],
  imports: [
    CommonModule,
    /**
     * If not imported, dialog frame will look weird when opened.
     */
    SecuredMaterialModule
  ]
})
export class DialogModule {}
