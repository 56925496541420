// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

const havrionB2C = {
  tenant: 'havrionhubb2c',
  clientId: `5db828a1-f301-442d-86de-c2a7c3b67b87`,
  redirectUri: {
    perform: window.location.origin + '/login',
    protect: 'https://protect-dev.havrion.com/login'
  },
  postLogoutRedirectUri: `${window.location.origin}/login`,
  userFlows: {
    signIn: `b2c_1a_signup_signin_totp_phone`,
    signUp: `B2C_1_Signup`,
    passwordReset: `b2c_1a_passwordreset_totp_phone`
  },
  userFlowsProtect: {
    signIn: `B2C_1_SignupSignin`,
    passwordReset: `B2C_1_PasswordReset`
  },
  otherKnownAutorities: <string[]>[],
  cacheLocation: 'sessionStorage',
  storeAuthStateInCookie: true,
  guardConfig: <MsalGuardConfiguration>{
    // Needed only if using MsalGuard
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['openid']
    },
    loginFailedRoute: '/login'
  },
  interceptorConfig: <MsalInterceptorConfiguration>{
    // Needed only if using msal interceptor
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      ['http://localhost:3500', ['openid']]
      // Add other resources required by the Application
    ])
  }
};

export const environment = {
  production: false,
  test: false,
  azureB2C: havrionB2C,
  performTVApi: {
    // url: 'http://localhost:7071/api'
    url: 'https://hp-api-01-dev.azurewebsites.net/api'
  }
};
