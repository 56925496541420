import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { UserAccounts, UserProfileResponse } from 'src/app/core/interfaces/usersResponse';
import { UserProfileService } from 'src/app/core/services/user-profile.service';
import { environment } from 'src/environments/environment';
import {
  Feature,
  FeaturesService
} from 'src/app/core/services/features.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Location } from '@angular/common';
import {
  SubscriptionManager,
  SubscriptionManagerService
} from 'src/app/core/services/subscription-manager.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public shouldShowBetaFeaturesOption: boolean;

  @Input('data') context: string;

  public enabledFeatures: {
    epg: boolean;
  };

  private subsManager: SubscriptionManager;

  public userProfile: UserProfileResponse | null;
  public enableSpinner = false;

  public sortedAccounts: UserAccounts[] = [];

  constructor(
    private authService: MsalService,
    private userProfileService: UserProfileService,
    private _snackBar: MatSnackBar,
    private location: Location,
    private subsManagerService: SubscriptionManagerService,
    private featuresService: FeaturesService
  ) {
    this.userProfile = null;
    this.subsManager = this.subsManagerService.create();
    this.subsManager.add(
      this.featuresService.features.subscribe((features) => {
        this.enabledFeatures = {
          epg: features & Feature.EPG ? true : false
        };
      })
    );
    this.shouldShowBetaFeaturesOption = false;
  }

  get allowPasswordReset(): boolean {
    const account = this.authService.instance.getAllAccounts()?.[0];
    return account?.idTokenClaims?.['idp'] === 'none';
  }

  ngOnInit(): void {
    this.getUserProfile();
  }

  ngOnDestroy(): void {
    this.subsManager.unsubscribe();
  }

  async getUserProfile() {
    if (this.context === '404') {
      return;
    }
    // const existingUserProfile = this.userProfileService.userProfile;
    // if (!existingUserProfile) {
    //   await this.userProfileService.getUserProfile();
    // }
    this.userProfile = this.userProfileService.userProfile ?? null;
    this.sortedAccounts = Array.from(this.userProfile.accounts).sort((acc1, acc2) => (
      acc1.name.localeCompare(acc2.name)
    ));
    this.shouldShowBetaFeaturesOption =
      this.hasEnabledBetaFeaturesThroughURL() ??
      this.userProfile?.allow_beta_features ??
      false;
  }

  private hasEnabledBetaFeaturesThroughURL(): boolean | null {
    if (environment.production) {
      return null;
    }
    const pathname = this.location.path();
    const searchParamsStartOffset = pathname.indexOf('?');
    if (searchParamsStartOffset === -1) {
      return null;
    }
    const params = new URLSearchParams(
      pathname.substring(searchParamsStartOffset)
    );
    return params.get('beta_features') === '1' ? true : null;
  }

  changeAccount(id: string) {
    let payload = {
      new_account_id: id
    };
    this.userProfileService.updateAccount(payload).subscribe({
      next: (res) => {
        // this.getUserProfile();
        window.location.reload();
      },
      error: (error) => {
        this.openSnackBar(error.message, 'Ok');
      }
    });
  }

  resetPassword() {
    this.enableSpinner = true;
    let passUrl = `https://${environment.azureB2C.tenant}.b2clogin.com/${environment.azureB2C.tenant}.onmicrosoft.com/${environment.azureB2C.userFlows.passwordReset}`;
    this.authService.loginRedirect({
      scopes: ['openid'],
      authority: passUrl
    });
  }

  logOut() {
    this.enableSpinner = true;
    this.authService.logout();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000
    });
  }

  toggleEPG(event: MatSlideToggleChange) {
    this.featuresService.enable(Feature.EPG, event.checked);
  }
}
