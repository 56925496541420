import { Component, OnInit } from '@angular/core';
import { EULAResponse } from '../../interfaces/eulaResponse';
import { EulaService } from '../../services/eula.service';

@Component({
  selector: 'app-privacy-policy-view',
  templateUrl: './privacy-policy-view.component.html',
  styleUrls: ['./privacy-policy-view.component.scss']
})
export class PrivacyPolicyViewComponent implements OnInit {

  constructor(private eulaService: EulaService) { }

  termsText: string = 'Loading...'

  ngOnInit(): void {
    this.eulaService.getPrivacyPolicyNoAuth().subscribe({
      next: (res: EULAResponse) => {
        const unnecessaryParagraphs = /<p class=MsoNormal(?: style="page-break-after:avoid")?><span lang=EN-US>&nbsp;<\/span><\/p>/g;
        this.termsText = res.privacy_policy.replace(unnecessaryParagraphs, '');
      }
    })
  }

  return(){
    window.history.go(-1); 
    return false;
  }

}
