import { NgModule } from '@angular/core';
import { TizenAppsDialogComponent } from './tizen-apps-dialog/tizen-apps-dialog.component';
import { CommonModule } from '@angular/common';
import { TizenAppsDialogNavigationHeaderComponent } from './tizen-apps-dialog-navigation-header/tizen-apps-dialog-navigation-header.component';
import { TizenAppsDialogTableComponent } from './tizen-apps-dialog-table/tizen-apps-dialog-table.component';
import { SecuredMaterialModule } from 'src/app/shared/secured-material/secured-material.module';
import { SecuredSharedModule } from 'src/app/shared/secured-shared/secured-shared.module';
import { TizenAppReleaseNotesComponent } from './tizen-app-release-notes/tizen-app-release-notes.component';
import { PendingDisplaysTableComponent } from './unaffected-displays-table/unaffected-displays-table.component';

@NgModule({
  declarations: [
    TizenAppsDialogTableComponent,
    TizenAppsDialogComponent,
    TizenAppReleaseNotesComponent,
    TizenAppsDialogNavigationHeaderComponent,
    PendingDisplaysTableComponent
  ],
  imports: [
    SecuredSharedModule,
    SecuredMaterialModule,
    CommonModule
  ],
})
export class TizenAppsModule { }
