import {
  EpisodeQuality,
  EpisodeScheduleType,
  IInputEpisode
} from 'src/app/core/services/extended-program-guide.service';
import { DateTime } from 'luxon';
import { IIncomingProgram } from '../types';

export default function toInputProgram(value: IIncomingProgram): IInputEpisode {
  return {
    schedule: {
      type: EpisodeScheduleType.Definite
    },
    options: {
      host: value.host ?? null,
      game:
        value.league || value.location || value.team_1 || value.team_2
          ? {
              name: value.league,
              location: value.location,
              team_1: value.team_1,
              team_2: value.team_2
            }
          : null,
      episode:
        value.episode_number ||
        value.season_number ||
        value.episode_title ||
        value.year ||
        value.cast
          ? {
              number: value.episode_number,
              title: value.episode_title,
              season: value.season_number,
              year: value.year,
              cast: value.cast
            }
          : null,
      hasClosedCaptions: value.has_captions,
      hasSubtitles: value.has_subtitle
    },
    description: value.description,
    duration: value.duration,
    quality: value.is_hd ? EpisodeQuality.HD : EpisodeQuality.Standard,
    show_thumbnail_url: value.showcard_path,
    start_date: DateTime.fromISO(value.start_date).toSeconds(),
    title_thumbnail_url: value.title_card_path,
    title: value.episode_title,
    name: value.name,
    viewer_rating: value.rating,
    show_type: value.show_type,
    starRating: value.star_rating
  };
}
