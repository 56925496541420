import { IInputIncomingProgram } from 'src/app/features/secured-skeleton/extended-program-guide/types';
import {
  EpisodeQuality,
  IInputEpisode
} from '../../../../core/services/extended-program-guide.service';

export default function inputProgramToOutgoing(
  inputProgram: IInputEpisode
): IInputIncomingProgram {
  const { options } = inputProgram;
  return {
    rating: inputProgram.viewer_rating,
    show_type: inputProgram.show_type,
    star_rating: inputProgram.starRating,
    has_subtitle: options.hasSubtitles,
    description: inputProgram.description,
    has_captions: options.hasClosedCaptions,
    is_always_on: false,
    showcard_path: inputProgram.show_thumbnail_url,
    title_card_path: inputProgram.title_thumbnail_url,
    episode_number: options.episode?.number ?? null,
    is_hd: inputProgram.quality === EpisodeQuality.HD,
    league: options.game?.name ?? null,
    location: options.game?.location ?? null,
    season_number: options.episode?.season ?? null,
    team_1: options.game?.team_1 ?? null,
    team_2: options.game?.team_2 ?? null,
    //year: options.episode?.year ?? null,
    //cast: options.episode?.cast ?? null,
    //host: options.host,
    episode_title: inputProgram.title ?? null,
    duration: inputProgram.duration,
    name: inputProgram.name,
    start_date: new Date(inputProgram.start_date * 1000).toISOString()
  };
}
