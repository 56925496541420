<div class="tizen-apps-dialog-navigation-header">
  <button
    *ngFor="let button of buttons"
    (click)="buttonClick.emit(button.id)"
    class="tizen-apps-dialog-navigation-header-button"
  >
    <mat-icon *ngIf="button.icon">
      {{ button.icon }}
    </mat-icon>
    <div class="tizen-apps-dialog-navigation-header-button-text">
      {{ button.title }}
    </div>
  </button>
  <div class="flex-fill tizen-apps-dialog-navigation-header-title">
    {{ title }}
  </div>
  <button
    *ngFor="let button of rightButtons"
    (click)="buttonClick.emit(button.id)"
    class="tizen-apps-dialog-navigation-header-button"
  >
    <mat-icon *ngIf="button.icon && button.position !== 'right'">
      {{ button.icon }}
    </mat-icon>
    <div class="tizen-apps-dialog-navigation-header-button-text">
      {{ button.title }}
    </div>
    <mat-icon *ngIf="button.icon && button.position === 'right'">
      {{ button.icon }}
    </mat-icon>
  </button>
</div>
