import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, lastValueFrom, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DispGrpTreeResponse,
  GrpMetadata,
} from '../interfaces/dGpTreeResponse';
import { handleError } from '../utils/generic-utils';

@Injectable({
  providedIn: 'root',
})
export class DpGroupTreeService {
  endpoint: string = environment.performTVApi.url;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    responseType: 'json',
  });
  retry = 0;

  private _disp_Grp_Tree: DispGrpTreeResponse[] = [];

  private _tree: GrpMetadata[] = [];

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}

  get dispGrpTree() {
    return this._disp_Grp_Tree;
    // return [];
  }

  addNode(elem: GrpMetadata, groups: GrpMetadata[] = this._tree) {
    if (groups.length == 0) {
      groups.push(elem);
    } else {
      for (let node of groups) {
        if (
          elem.parentGroupId == node.parentGroupId
        ) {
          groups.push(elem);
          break;
        } else {
          if (
            elem.parentGroupId == node.groupId
          ) {
            if (!node.groups) {
              node.groups = [];
            }
            node.groups.push(elem);
            break;
          } else {
            if (
              node.groups?.length > 0
            ) {
              this.addNode(elem, node.groups);
            }
          }
        }
      }
    }
  }

  buildTree(displayGroups: DispGrpTreeResponse[]) {
    this._disp_Grp_Tree = [];
    for (let grp of displayGroups) {
      this._tree = [];
      if (grp.groups) {
        for (let dGp of grp.groups) {
          this.addNode(dGp);
        }
      }
      this._disp_Grp_Tree.push({
        ...grp,
        groups: this._tree,
        totalNodes: this.calcTotalNodes() + this._tree.length,
      });
    }
  }

  calcTotalNodes(tree: GrpMetadata[] = this._tree): number {
    let totalArr = 0;
    for (let grp of tree) {
      if (grp.groups?.length > 0) {
        let total = this.calcTotalNodes(grp.groups);
        grp.totalNodes = grp.groups.length + total;
      } else {
        grp.totalNodes = 0;
      }
      totalArr = totalArr + grp.totalNodes;
    }
    return totalArr;
  }

  async getDeviceTree() {
    let api = `${this.endpoint}/device-groups/magic-info`;
    await lastValueFrom(
      this.http
        .get(api, { headers: this.headers, responseType: 'json' })
        .pipe(retry(this.retry), catchError(handleError))
    )
      .then((res: DispGrpTreeResponse[]) => {

        this.buildTree(res);
      })
      .catch((error) => {
        this._disp_Grp_Tree = undefined;
        this.openSnackBar(error.message, 'Ok');
      });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

}
