import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/core/services/user-profile.service';

@Component({
  selector: 'logged-skeleton',
  templateUrl: 'logged-skeleton.component.html',
  styleUrls: ['logged-skeleton.component.scss']
})
export class LoggedSkeletonComponent implements OnInit {
  get isLoadingUserProfile(): boolean {
    return this.userProfileService.isLoadingUserProfile;
  }

  constructor(private userProfileService: UserProfileService) {}

  ngOnInit(): void {
    if (!this.userProfileService.userProfile) {
      this.userProfileService.getUserProfile();
    }
  }
}
