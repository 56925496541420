<app-header
  [data]="'404'"
  style="width: 100%; position: absolute; top: 0"
></app-header>
<div class="page-not-found__container">

  <div class="page-not-found__box">
    <div class="left404">
      <h1>404</h1>
      <div class="message404">
        <h2>The URL you requested was not found.</h2>

        Please use the following link to return:
        <ul>
          <li><a style="cursor: pointer;" (click)="return()">Go Back</a></li>
        </ul>
      </div>
    </div>

    <div class="right404">
      <img
        src="../../../../assets/images/404-image.png"
        border="0"
        style="width: 100%; max-width: 500px"
        alt="404"
      />
    </div>

  </div>
  <app-footer style="width: 100%; position: absolute; bottom: 0"></app-footer>
</div>
