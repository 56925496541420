import { Component, Input, OnInit } from '@angular/core';
import { TizenAppVersionDetails } from 'src/app/core/services/tizenApp.service';

@Component({
  selector: 'app-tizen-app-release-notes',
  templateUrl: './tizen-app-release-notes.component.html',
  styleUrls: ['./tizen-app-release-notes.component.scss']
})
export class TizenAppReleaseNotesComponent implements OnInit {
  @Input() public version: TizenAppVersionDetails;

  constructor() { }

  ngOnInit(): void {
  }

}
