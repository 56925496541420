import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionManager } from 'src/app/core/services/subscription-manager.service';
import { TizenAppDetailsResponse, TizenAppService, TizenAppVersionDetails } from 'src/app/core/services/tizenApp.service';
import TizenAppsVersionUtil from '../version';

export enum TizenAppsDialogTableButtonType {
  ReleaseNotes
}

export interface ITizenAppsDialogTableButtonClickEvent {
  element: TizenAppVersionDetails;
  title: string;
  button: TizenAppsDialogTableButtonType;
}

export interface TizenAppVersionDetailsRow extends TizenAppVersionDetails {
  showOverlay?: boolean;
}

@Component({
  selector: 'app-tizen-apps-dialog-table',
  templateUrl: './tizen-apps-dialog-table.component.html',
  styleUrls: ['./tizen-apps-dialog-table.component.scss']
})
export class TizenAppsDialogTableComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @Output() onRowButtonClicked = new EventEmitter<ITizenAppsDialogTableButtonClickEvent>();

  constructor(
    private readonly tizenAppService: TizenAppService,
    private readonly _snackBar: MatSnackBar
  ) { }

  dataSource = new MatTableDataSource<TizenAppVersionDetailsRow>();
  displayedColumns: string[] = ['version', 'total_displays', 'release_date', 'release_notes'];

  loading = false;

  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 20, 50];
  length = 0;
  pageEvent: PageEvent;
  subscriptionManager = new SubscriptionManager();
  versionsWithFailedDisplays = new Array<string>();

  showOverlay: boolean = false;

  @Input() selectedAppVersion: number;
  @Input() tizenAppDetails: TizenAppDetailsResponse;
  @Input() deployingMode: boolean = false;
  @Output() selectedAppVersionChange = new EventEmitter<number>();
  @Output() viewOutdatedDisplaysInfo = new EventEmitter<string>();

  ngOnInit(): void {
    // Set data source data with the tizenAppDetails input
    // and add showOverlay property to each version
    const data = this.tizenAppDetails.versions.map(version => {
      return {
        ...version,
        showOverlay: false
      };
    });

    this.dataSource.data = data;
    this.dataSource._updateChangeSubscription();

    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tizenAppDetails']) {
      // Keep the showOverlay property of each version
      // and update the data source with the new versions
      const data = (changes['tizenAppDetails'].currentValue.versions as TizenAppVersionDetails[]).map(version => {
        const existingVersion = this.dataSource.data.find(v => v.version === version.version);
        return {
          ...version,
          // If the version has total_displays == 0, set showOverlay to false 
          showOverlay: existingVersion && version.total_displays > 0 ? existingVersion.showOverlay : false
        };
      });

      this.dataSource.data = data;
    }
  }

  ngOnDestroy(): void {
    this.subscriptionManager.unsubscribe();
  }

  downloadFile(url: string = '') {
    window.open(url || this.tizenAppDetails.installer_url);
  }

  handlePageEvent(event: PageEvent) {
    this.pageEvent = event;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  selectNewVersion(event: MatRadioChange) {
    this.selectedAppVersion = event.value;
    this.selectedAppVersionChange.emit(this.selectedAppVersion);
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 6000,
    });
  }

  deployProgress() {
    // ToDo: Implement real-time progress logic
    // return Math.ceil(((this.deployState.updated_display_count + this.deployState.failed_display_count) / this.deployState.total_display_count) * 100);
  }

  onClickReleaseNotesButton(element: TizenAppVersionDetails) {
    this.onRowButtonClicked.emit({
      element,
      title: `HTV v${element.version} Release Notes`,
      button: TizenAppsDialogTableButtonType.ReleaseNotes
    });
  }

  requiresControlPlaneUpgrade(row: TizenAppVersionDetails): boolean {
    const currentVersion = this.tizenAppDetails.versions.find(app => app.is_current);
    if(!currentVersion) {
      return false;
    }
    return TizenAppsVersionUtil.greaterThan(row.control_plane_version, currentVersion.control_plane_version);
  }

  getControlPlaneUpgradeTooltip(_: TizenAppVersionDetails): string {
    return `Control Plane will be updated before installing this version`;
  }

  onShowOutdatedDisplaysInfo(version: string) {
    this.clearOverlay();
    this.viewOutdatedDisplaysInfo.emit(version);
  }

  clearOverlay() {
    this.dataSource.data.forEach((row) => {
      row.showOverlay = false;
    });
  }
}

